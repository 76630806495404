import { Printer, Search } from "lucide-react";
import { useSelector } from "react-redux";
import { getUserData } from "../store/login.slice";
import { useState } from "react";

function Reports() {
  const userData = useSelector(getUserData);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [selectedOption, setSelectedOption] = useState("score");
  const [selectedSubOptions, setSelectedSubOptions] = useState({
    correct: false,
    incorrect: false,
    omitted: false,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedSubOptions((prevOptions) => ({
      ...prevOptions,
      [value]: checked,
    }));
  };

  const handlePrint = () => {
    window.print(); // Triggers the browser's print dialog for the whole page
  };

  // const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  /*const onSort = (columnKey) => {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...tempdata].sort((a, b) => {
      if (a[columnKey] < b[columnKey])
        return direction === "ascending" ? -1 : 1;
      if (a[columnKey] > b[columnKey])
        return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };*/

  /*const renderSortIcon = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };*/

  const filteredData = userData.category.filter(
    (row) => row.Category.toLowerCase().includes(searchQuery.toLowerCase()) // Filter based on search query
  );

  return (
    <div>
      <div className="border-b border-gray-300 p-4 flex justify-between text-primaryText">
        <span className="text-2xl">Reports</span>
        <button
          className="flex gap-3 text-[#6196f5] font-semibold"
          onClick={handlePrint}
        >
          <Printer />
          Print
        </button>
      </div>
      <div className="bg-primaryBackground text-primaryText m-4 rounded">
        <div className="p-6">
          <div className="flex">
            <div className="flex text-gray-500 flex items-center justify-between w-full">
              {/* Show Dropdown */}
              <div className="flex items-center gap-4 m-4 h-[40px] ">
                <span className="font-semibold">Show:</span>
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="p-2 border border-gray-300 rounded bg-secondary"
                >
                  <option value="score">Score</option>
                  <option value="qbankUsage">Qbank Usage</option>
                </select>
                {/* Dropdown input with checkboxes */}
                {selectedOption != "qbankUsage" && (
                  <div className="flex items-center gap-4 m-4">
                    <div className="relative">
                      <button
                        onClick={toggleDropdown}
                        className="border border-gray-300 rounded p-2 bg-secondary"
                      >
                        Select Options
                      </button>

                      {dropdownOpen && (
                        <div className="absolute border border-gray-300 rounded mt-2 w-48 shadow-lg z-10 bg-secondary">
                          <div className="p-2">
                            <label className="block">
                              <input
                                type="checkbox"
                                value="correct"
                                checked={selectedSubOptions["correct"]}
                                onChange={handleCheckboxChange}
                                className="mr-2"
                              />
                              Correct
                            </label>
                            <label className="block">
                              <input
                                type="checkbox"
                                value="incorrect"
                                checked={selectedSubOptions["incorrect"]}
                                onChange={handleCheckboxChange}
                                className="mr-2"
                              />
                              Incorrect
                            </label>
                            <label className="block">
                              <input
                                type="checkbox"
                                value="omitted"
                                checked={selectedSubOptions["omitted"]}
                                onChange={handleCheckboxChange}
                                className="mr-2"
                              />
                              Omitted
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-4 m-4 h-[40px]">
                {/* Search Bar */}
                <input
                  type="text"
                  className="px-4 w-[250px] h-[40px] rounded border-b border-gray-300"
                  placeholder="Search by Category Name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the search query state
                />
                <Search className="mt-[6px] mx-1" />
              </div>
            </div>
          </div>
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="text-gray-600">
                <th className="text-left px-4 py-2">Name</th>
                <th className="text-left px-4 py-2">Usage</th>
                <th className="text-left px-4 py-2">Correct</th>
                <th className="text-left px-4 py-2">Incorrect</th>
                <th className="text-left px-4 py-2">Omitted</th>
                {/*<th className="text-left px-4 py-2">P-rank</th>*/}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => {
                const totalAnswered =
                  row.TotalNumberCorrectInCategory +
                  row.TotalNumberInCorrectInCategory +
                  row.TotalNumberOmittedInCategory;

                const correctPercentage = (
                  (row.TotalNumberCorrectInCategory /
                    (row.TotalNumberOfQuestionInCategory || 1)) *
                  100
                ).toFixed(2);

                const incorrectPercentage = (
                  (row.TotalNumberInCorrectInCategory /
                    (row.TotalNumberOfQuestionInCategory || 1)) *
                  100
                ).toFixed(2);

                const omittedPercentage = (
                  (row.TotalNumberOmittedInCategory /
                    (row.TotalNumberOfQuestionInCategory || 1)) *
                  100
                ).toFixed(2);

                const usagePercentage = (
                  (totalAnswered / (row.TotalNumberOfQuestionInCategory || 1)) *
                  100
                ).toFixed(2);
                const isSelected = selectedRow === index;

                return (
                  <tr
                    key={index}
                    className={`bg-primaryBackground text-primaryText hover:bg-secondaryBackground cursor-pointer ${
                      isSelected ? "bg-gray-300" : ""
                    }`}
                    onClick={() => setSelectedRow(isSelected ? null : index)}
                  >
                    <td className="px-4 py-2 font-normal h-[76px]">
                      {row.Category}
                      {selectedOption == "qbankUsage" && (
                        <div className="mt-2 h-2 w-full bg-gray-200 rounded">
                          <div
                            className={`h-full rounded bg-blue-500`}
                            style={{ width: `${usagePercentage}%` }}
                          ></div>
                        </div>
                      )}
                      {selectedOption != "qbankUsage" && (
                        <div className="mt-2 h-2 flex">
                          <div className="progress custom-progress d-print-none">
                            {selectedSubOptions["correct"] && (
                              <div
                                className="progress-bar bg-green-500"
                                style={{ width: `${correctPercentage}%` }}
                              ></div>
                            )}
                            {selectedSubOptions["incorrect"] && (
                              <div
                                className="progress-bar bg-red-500"
                                style={{ width: `${incorrectPercentage}%` }}
                              ></div>
                            )}
                            {selectedSubOptions["omitted"] && (
                              <div
                                className="progress-bar bg-yellow-500"
                                style={{ width: `${omittedPercentage}%` }}
                              ></div>
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-2">{`${totalAnswered} / ${row.TotalNumberOfQuestionInCategory}`}</td>
                    <td className="px-4 py-2">{`${row.TotalNumberCorrectInCategory} (${correctPercentage}%)`}</td>
                    <td className="px-4 py-2">{`${row.TotalNumberInCorrectInCategory} (${incorrectPercentage}%)`}</td>
                    <td className="px-4 py-2">{`${row.TotalNumberOmittedInCategory} (${omittedPercentage}%)`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Reports;
