import {
  BookCopy,
  ChevronLeft,
  ChevronRight,
  Cog,
  DoorOpen,
  Megaphone,
  Menu,
  NotepadText,
  Pen,
  PenSquareIcon,
  Square,
  Strikethrough,
  X,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import {
  useGetQuestionByQuestionIdQuery,
  useGetQuestionImageByIdQuery,
} from "../api/questionsSlice/questionsSlice";
import CheckBox from "../components/checkbox/checkbox";
import Checkbox from "../components/checkbox/checkbox";
import Modal from "../components/modal/Modal";
import ExamTimer from "../components/examTimer/examTimer";
import ToolTip from "../components/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  addTestExtraSeconds,
  getCreatedTest,
  getTestOutOfTime,
  getWhenTestRanOutOfTime,
  setReviewedExam,
} from "../store/test.slice";
import QuestionNavigator from "../components/navigator/Navigator";
import { useSubmitFeedbackByQuestionIdMutation } from "../api/feedbackSlice/feedbackSlice";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";
import UniChart from "../components/UniChart/unichart";
import ExamSaver from "../components/examSaver/examSaver";
import {
  getDistanceToStartOfContainerBreak,
  getSelectedContainers,
  processQuestionLabel,
  processQuestionText,
} from "../helpers/testHelpers";
import TestCenterColumns from "../components/testCenterColumns/testCenterColumns";
import { Warnings } from "../constants/test";
import {
  getModelFetchingData,
  getUserData,
  getUserDataTheme,
  hideSidebar,
  setReviewHighYield,
} from "../store/login.slice";
import { toast } from "react-toastify";
import { useGardeTestMutation } from "../api/testSlice.ts/testSlice";
import { useNavigate, useParams } from "react-router-dom";
import { darkThemes } from "../constants/themes";

function Test() {
  //
  //Overrides TODO DISABLE BEFORE RELEASE
  //
  const { questionId } = useParams(); // Get questionId from the URL
  //
  //Css Override functionality
  //
  const [sizing, setSizing] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  function handleWindowSizeChange() {
    setSizing({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const theme = useSelector(getUserDataTheme);
  const inDarkMode = darkThemes.includes(theme);
  //Experimental CSS override, normally not acceptable but works well enough for now.
  const centerColumSize = sizing.height * 0.85 - (1080 - sizing.height) * 0.15;
  const centerColumSizeReview =
    sizing.height < 1080
      ? sizing.height * 0.883 - (1080 - sizing.height) * 0.15
      : sizing.height * 0.883 - (1080 - sizing.height) * 0.1;

  //
  //Mobile mode
  //
  const isMobile = sizing.width <= 640;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  //
  //Hooks
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //
  //Selectors
  //
  const userData = useSelector(getUserData);
  const createdExam = useSelector(getCreatedTest);
  const testOutOfTime = useSelector(getTestOutOfTime);
  const whenTestRanOutOfTime = useSelector(getWhenTestRanOutOfTime);
  const modelFetchingData = useSelector(getModelFetchingData);
  //
  //Data
  //
  let modifiedKeyIndexes = 0;
  const maxQuestions =
    questionId != undefined
      ? ["overrideQuestion"]
      : createdExam.QuestionsForTest.length;
  const initialScrollState = Array(maxQuestions).fill({
    left: false,
    right: false,
  });

  //Initial state data creation
  const initialAnswerData = createdExam.QuestionsForTest.reduce(
    (acc, question) => {
      acc[question] = [];
      return acc;
    },
    {}
  );

  const initialNotesData = createdExam.QuestionsForTest.reduce(
    (acc, question) => {
      acc[question] = "";
      return acc;
    },
    {}
  );

  const initialReviewData = createdExam.QuestionsForTest.reduce(
    (acc, question) => {
      acc[question] = false;
      return acc;
    },
    {}
  );

  const initialMarkedData = createdExam.QuestionsForTest.reduce(
    (acc, question) => {
      acc[question] = false;
      return acc;
    },
    {}
  );

  let initialAnswerChoices = {};
  const initialAnswerOrders = {};
  const initialAnswerChanges = {};
  const initialTextModifications = {};
  let initialTutorialMode = [];
  if (createdExam?.CapturedData?.notesById) {
    Object.keys(createdExam.CapturedData.notesById).forEach((key) => {
      initialNotesData[key] = createdExam.CapturedData.notesById[key];
    });
  }
  if (createdExam?.CapturedData?.reviewById) {
    Object.keys(createdExam.CapturedData.reviewById).forEach((key) => {
      initialReviewData[key] = createdExam.CapturedData.reviewById[key];
    });
  }

  if (createdExam?.CapturedData?.answersById) {
    Object.keys(createdExam.CapturedData.answersById).forEach((key) => {
      initialAnswerData[key] = createdExam.CapturedData.answersById[key];
    });
  }

  if (createdExam?.AnswerChoicesForTest) {
    const result = createdExam.AnswerChoicesForTest.reduce((acc, item) => {
      acc[item.question_id] = item.answer_serials;
      return acc;
    }, {});
    initialAnswerChoices = { ...result };
  } else if (createdExam?.CapturedData?.answerChoices) {
    Object.keys(createdExam.CapturedData.answerChoices).forEach((key) => {
      initialAnswerChoices[key] = createdExam.CapturedData.answerChoices[key];
    });
  }

  if (createdExam?.CapturedData?.answerOrdersById) {
    Object.keys(createdExam.CapturedData.answerOrdersById).forEach((key) => {
      initialAnswerOrders[key] = createdExam.CapturedData.answerOrdersById[key];
    });
  }

  if (createdExam?.CapturedData?.answerChangesById) {
    Object.keys(createdExam.CapturedData.answerChangesById).forEach((key) => {
      initialAnswerChanges[key] =
        createdExam.CapturedData.answerChangesById[key];
    });
  }

  if (createdExam?.CapturedData?.textModificationsById) {
    Object.keys(createdExam.CapturedData.textModificationsById).forEach(
      (key) => {
        initialTextModifications[key] =
          createdExam.CapturedData.textModificationsById[key];
      }
    );
  }

  if (createdExam?.CapturedData?.tutorialMode) {
    initialTutorialMode = createdExam.CapturedData.tutorialMode;
  }

  //End initial data creation

  //
  //Local state hooks
  //
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  useTokenSpoilCheck(errorStatusCode);
  const [exitExamOpen, setExitExamOpen] = useState(false);
  const [scrollChecks, setScrollChecks] = useState(initialScrollState);
  const [examinationState, setExaminationState] = useState({
    currentQuestionIndex: 0,
    reviewMode: false,
    viewAnswerMode: false,
    reviewingIncompleteQuestions: false,
    reviewingAllQuestions: false,
    reviewingMarkedQuestions: false,
    answersById: { ...initialAnswerData },
    notesById: { ...initialNotesData },
    textModificationsById: { ...initialTextModifications },
    reviewById: { ...initialReviewData },
    markedById: { ...initialMarkedData },
    answerChangesById: { ...initialAnswerChanges },
    answerOrdersById: { ...initialAnswerOrders },
    answerChoices: { ...initialAnswerChoices },
    tutorialMode: [...initialTutorialMode],
    prescriptions: {},
    incompleteToBeReviewed: [],
  });
  const currentQuestionId =
    questionId != undefined
      ? questionId
      : createdExam.QuestionsForTest[examinationState.currentQuestionIndex]; //Not a state but important to keep around to shorten many lines*/
  const [warningsState, setWarningsState] = useState({
    incomplete: false,
    view: false,
    end: false,
  });
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [textSize, setTextSize] = useState({
    left: "text-base",
    right: "text-sm",
  });
  const [notesOpen, setNotesOpen] = useState(false);
  const [feedback, setFeedback] = useState({
    open: false,
    feedback: "",
    result: "",
  });
  const [navigator, setNavigator] = useState({
    open: false,
  });
  const [textSettingsOpen, setTextSettingsOpen] = useState(false);
  const [textEditModes, setTextEditModes] = useState({
    highlight: false,
    strikeThrough: false,
  });

  //
  //Queries and mutatios
  //

  const {
    data: currentQuestion,
    isFetching,
    isLoading,
  } = useGetQuestionByQuestionIdQuery({
    question_id: currentQuestionId,
    choices: examinationState.answerChoices,
  });

  const {
    data: currentQuestionImage,
    isLoading: questionImageFirstLoad,
    isFetching: questionImageLoading,
  } = useGetQuestionImageByIdQuery({
    question_id: currentQuestionId,
  });

  const imageSource =
    currentQuestionImage && currentQuestionImage.type.startsWith("image/")
      ? URL.createObjectURL(currentQuestionImage)
      : null;

  const [gardeTest] = useGardeTestMutation();

  const [
    submitFeedback,
    {
      isLoading: feedbackIsLoading,
      isSuccess: feedbackIsSuccess,
      reset: feedbackReset,
    },
  ] = useSubmitFeedbackByQuestionIdMutation();

  const isModelLoading =
    modelFetchingData[currentQuestion?.GetQuestion?.image_path]?.loading ||
    false;

  //
  //useEffect hooks
  //
  useEffect(() => {
    const choices = [];
    if (
      currentQuestion?.GetAnswer &&
      examinationState.answerChoices[currentQuestion.GetQuestion.question_id] ==
        undefined
    ) {
      currentQuestion?.GetAnswer.forEach((element) => {
        if (element.answer_serial) {
          choices.push(element.answer_serial);
        } else {
          console.error("NO SERIAL PRESENT");
        }
      });

      if (currentQuestion) {
        setExaminationState((prevState) => ({
          ...prevState,
          answerChoices: {
            ...prevState.answerChoices,
            [currentQuestion.GetQuestion.question_id]: [...choices],
          },
        }));
      }
    }
  }, [currentQuestion]);

  useEffect(() => {
    handleWindowSizeChange();
  }, [navigator.open, examinationState.viewAnswerMode]);

  //
  //Copy protection
  //
  useEffect(() => {
    // Disable right-click context menu
    const disableContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", disableContextMenu);

    // Disable copy and cut keyboard shortcuts
    const disableCopyCut = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "x")
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", disableCopyCut);

    // Disable the copy event
    const disableCopyEvent = (event) => {
      event.preventDefault();
    };
    document.addEventListener("copy", disableCopyEvent);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableCopyCut);
      document.removeEventListener("copy", disableCopyEvent);
    };
  }, []);

  // Refresh protection

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Refreshing this page will return you to the login page. Are you sure you want to leave this test? You can continue this test from the incomplete tests page.";
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //
  //Util functions
  //
  //Update notes logic
  //
  const handleNotesChange = (event) => {
    setExaminationState((prevState) => ({
      ...prevState,
      notesById: {
        ...prevState.notesById,
        [currentQuestionId]: event.target.value,
      },
    }));
  };

  //
  //Text mod logic
  //

  const handleTextSelection = useCallback(() => {
    const selection = window.getSelection();
    //const selectedText = selection.toString();
    let removeMode = false;
    const currentMode = textEditModes.highlight ? "highlight" : "strikethrough";
    // Ensure there is a selection
    if (selection.rangeCount > 0) {
      const selectedTextByContainer = {};

      for (let i = 0; i < selection.rangeCount; i++) {
        const range = selection.getRangeAt(i); // Get the current range

        // Function to get the parent element with data-id
        const getParentWithDataId = (node) => {
          while (node) {
            if (
              node.getAttribute &&
              node.getAttribute("data-id") !== null &&
              node.getAttribute("data-id") !== "container-break" &&
              !node.getAttribute("data-id").includes("sortingOrder")
            ) {
              return node;
            }
            node = node.parentNode;
          }
          return null; // Return null if no parent found
        };

        // Extract start and end containers for the range
        const startContainer = range.startContainer;
        const endContainer = range.endContainer;
        // Handle selection across multiple containers
        const containers = getSelectedContainers(range);
        // Extract selected text from each container
        containers.forEach((container) => {
          // Get the parent with data-id
          const parentWithDataId = getParentWithDataId(container);
          // @ts-expect-error. no need for type definition here
          const text = container.textContent; // Get the text content of the container
          let rangeStart =
            range.startOffset + getDistanceToStartOfContainerBreak(container);
          let rangeEnd = range.endOffset - range.startOffset + rangeStart;

          if (container == startContainer && container == endContainer) {
            rangeStart =
              range.startOffset + getDistanceToStartOfContainerBreak(container);
            rangeEnd = range.endOffset - range.startOffset + rangeStart;

            if (rangeStart == rangeEnd) {
              removeMode = true;
            }
          } else if (container == startContainer && container != endContainer) {
            rangeStart =
              range.startOffset + getDistanceToStartOfContainerBreak(container);
            rangeEnd = text.length - range.startOffset + rangeStart;
          } else if (container != startContainer && container != endContainer) {
            rangeStart =
              range.startOffset + getDistanceToStartOfContainerBreak(container);
            rangeEnd = text.length + rangeStart;
          } else if (container != startContainer && container == endContainer) {
            rangeStart = getDistanceToStartOfContainerBreak(container);
            rangeEnd = range.endOffset + rangeStart;
          }

          const containerId = parentWithDataId.getAttribute("data-id"); // Get data-id

          const selectedText = text.substring(rangeStart, rangeEnd); // Extract selected text portion

          // Store selected text info
          if (!selectedTextByContainer[containerId]) {
            selectedTextByContainer[containerId] = {
              text: selectedText,
              start: rangeStart,
              end: rangeEnd,
              mode: currentMode,
            };
          } else {
            selectedTextByContainer[containerId].text += selectedText; // Append selected text
            selectedTextByContainer[containerId].end = rangeEnd; // Update end offset
          }

          // Log the selection details per parent with data-id
          /* console.log(
              `Container ID: ${containerId}, Selected Text: "${selectedText}", Overall Start: ${rangeStart}, Overall End: ${rangeEnd}`
            );*/
        });
      }

      setExaminationState((prevState) => {
        //const currentQuestionIndex = prevState.currentQuestionIndex; // Get the current question index
        const newTextModificationsById = {
          ...prevState.textModificationsById,
        };

        // Ensure the currentQuestionIndex key exists
        if (!newTextModificationsById[currentQuestionId]) {
          newTextModificationsById[currentQuestionId] = {};
        }

        // Access or create the specific text modifications object for this question
        const textModificationsForQuestion =
          newTextModificationsById[currentQuestionId];

        if (removeMode) {
          // Assuming selectedTextByContainer contains only one entry
          // @ts-expect-error. no need for type definition here
          const [parentId, { start, end }] = Object.entries(
            selectedTextByContainer
          )[0];

          // Ensure the parentId exists in textModificationsForQuestion
          if (textModificationsForQuestion[parentId]) {
            // Filter out the range that matches the start value
            textModificationsForQuestion[parentId] =
              textModificationsForQuestion[parentId] =
                textModificationsForQuestion[parentId].filter(
                  (range) => !(range.start <= start && range.end >= end)
                );
          }
        } else {
          Object.entries(selectedTextByContainer).forEach(
            // @ts-expect-error. no need for type definition here
            ([parentId, { start, end, mode }]) => {
              // Initialize the array if it doesn't exist
              if (!textModificationsForQuestion[parentId]) {
                textModificationsForQuestion[parentId] = [];
              }

              // Create a new range object for the new selection, including the mode
              const newRange = { start, end, mode };

              // Function to merge or adjust ranges based on mode
              const mergeRanges = (existingRanges, newRange) => {
                const mergedRanges = [];
                let pushed = false; // Track whether we've pushed any existing ranges

                existingRanges.forEach((range) => {
                  if (range.mode === newRange.mode) {
                    // Merge if modes match and ranges overlap or are contained
                    if (
                      (newRange.start <= range.end &&
                        newRange.end >= range.start) || // Overlapping ranges
                      (newRange.start >= range.start &&
                        newRange.end <= range.end) // Contained range
                    ) {
                      newRange.start = Math.min(newRange.start, range.start);
                      newRange.end = Math.max(newRange.end, range.end);
                    } else {
                      // No overlap, just keep the existing range
                      mergedRanges.push(range);
                    }
                  } else {
                    // Modes differ: Handle overlaps by adjusting the old range
                    if (
                      newRange.start <= range.end &&
                      newRange.end >= range.start
                    ) {
                      if (
                        newRange.start > range.start &&
                        newRange.end < range.end
                      ) {
                        // Case 1: New range is contained within old range — split the old range into two
                        mergedRanges.push({
                          start: range.start,
                          end: Math.max(0, newRange.start - 1), // Offset end of the first split
                          mode: range.mode,
                        });
                        mergedRanges.push({
                          start: Math.max(0, newRange.end + 1), // Offset start of the second split
                          end: range.end,
                          mode: range.mode,
                        });
                      } else if (newRange.start > range.start) {
                        // Case 2: Overlap on the start side — move old range's end to new range's start
                        range.end = Math.max(0, newRange.start - 1); // Offset the end by 1
                        mergedRanges.push(range);
                      } else if (newRange.end < range.end) {
                        // Case 3: Overlap on the end side — move old range's start to new range's end
                        range.start = Math.max(0, newRange.end + 1); // Offset the start by 1
                        mergedRanges.push(range);
                      }
                      pushed = true;
                    } else {
                      // No overlap, keep the existing range as is
                      mergedRanges.push(range);
                    }
                  }
                });

                if (!pushed) {
                  // Add the new range if no existing ranges were modified
                  mergedRanges.push(newRange);
                }

                return mergedRanges;
              };

              // Update the ranges for this parentId
              textModificationsForQuestion[parentId] = mergeRanges(
                textModificationsForQuestion[parentId],
                newRange
              );
            }
          );
        }

        return {
          ...prevState,
          textModificationsById: newTextModificationsById,
        };
      });

      // Deselect text after processing
      selection.removeAllRanges();
    } else {
      //console.log("No text is selected.");
    }

    return;
  }, [currentQuestionId, textEditModes.highlight]);

  //Highlighting logic
  useEffect(() => {
    if (textEditModes.highlight || textEditModes.strikeThrough) {
      document.addEventListener("mouseup", handleTextSelection);
      document.addEventListener("touchend", handleTextSelection);
    } else {
      document.removeEventListener("mouseup", handleTextSelection);
      document.removeEventListener("touchend", handleTextSelection);
    }

    return () => {
      document.removeEventListener("mouseup", handleTextSelection);
      document.removeEventListener("touchend", handleTextSelection);
    };
  }, [
    handleTextSelection,
    textEditModes.highlight,
    textEditModes.strikeThrough,
  ]);

  // Helper function to apply highlighting for all highlighted texts
  const getHighlightedText = (text, parentId) => {
    if (typeof text == "number") {
      text = String(text);
    }
    // Retrieve highlighted ranges for the specific parentId under the currentQuestionIndex
    //const currentQuestionIndex = examinationState.currentQuestionIndex;
    const highlightedRanges = [
      ...(examinationState.textModificationsById[currentQuestionId]?.[
        parentId
      ] || []),
    ];

    // If no highlighted ranges for this element, return original trimmed text
    if (!highlightedRanges || highlightedRanges.length === 0) return text;

    // Create an array to hold parts of the text with highlighting
    const parts = [];
    let lastIndex = 0;
    const sortedRanges = highlightedRanges.sort((a, b) => a.start - b.start);
    // Iterate through the highlighted ranges
    sortedRanges.forEach(({ start, end, mode }) => {
      // Add text before the current highlight

      if (lastIndex < start) {
        modifiedKeyIndexes++;
        parts.push(
          <span
            data-id={`${parentId}-sortingOrder:${lastIndex}`}
            key={`${parentId}-sortingOrder:${modifiedKeyIndexes}`}
          >
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      // Add the highlighted part of the text
      modifiedKeyIndexes++;
      parts.push(
        <span
          data-id={`${parentId}-sortingOrder:${start}`}
          key={`${parentId}-sortingOrder:${modifiedKeyIndexes}`}
          style={
            mode == "highlight"
              ? { backgroundColor: inDarkMode ? "#654321" : "yellow" }
              : { textDecoration: "line-through" }
          }
        >
          {text.slice(start, end)}
        </span>
      );

      // Update the last index
      lastIndex = end;
    });

    // Add any remaining text after the last highlight
    if (lastIndex < text.length) {
      modifiedKeyIndexes++;
      parts.push(
        <span
          data-id={`${parentId}-sortingOrder:${lastIndex}`}
          key={`${parentId}-sortingOrder:${modifiedKeyIndexes}`}
        >
          {text.slice(lastIndex)}
        </span>
      );
    }
    // Return a single element that represents the entire text with highlights
    return <>{parts}</>;
  };

  //
  //Answering logic
  //

  const handleAnswerChecked = (serial) => {
    //const currentQuestionIndex = examinationState.currentQuestionIndex;

    if (examinationState.tutorialMode.includes(currentQuestionId)) {
      toast("You have already viewed the answer for this question");
      return;
    }

    const included =
      examinationState.answersById[currentQuestionId] != undefined &&
      examinationState.answersById[currentQuestionId].includes(serial);

    // Get the current question index and answer changes for this question
    const currentAnswerHistory =
      examinationState.answerChangesById[currentQuestionId] || [];

    // Create a new copy of the currentAnswerHistory to avoid mutating state
    const newAnswerHistory = [...currentAnswerHistory];

    if (included) {
      // Answer was already selected, remove it from the selected list and mark the choice as false
      newAnswerHistory.push({
        id: serial,
        choice: false,
      });

      const updatedAnswerById = { ...examinationState.answersById };

      const currentQuestionAnswers = updatedAnswerById[currentQuestionId];

      updatedAnswerById[currentQuestionId] = currentQuestionAnswers.filter(
        (choice) => choice !== serial
      );

      // Update state by removing the choice from the selected answers
      setExaminationState((prevState) => ({
        ...prevState,
        answersById: { ...updatedAnswerById },
        answerChangesById: {
          ...prevState.answerChangesById,
          [currentQuestionId]: newAnswerHistory,
        },
      }));
    } else {
      // Answer was not selected, add it to the selected list and mark the choice as true
      newAnswerHistory.push({
        id: serial,
        choice: true,
      });

      const updatedAnswerById = { ...examinationState.answersById };
      // deep clone
      const updatedAnswers = [...updatedAnswerById[currentQuestionId]]; // Clone

      updatedAnswers.push(serial);

      updatedAnswerById[currentQuestionId] = updatedAnswers;

      // Update state with the new selected answers and answer changes
      setExaminationState((prevState) => ({
        ...prevState,
        answersById: updatedAnswerById,
        answerChangesById: {
          ...prevState.answerChangesById,
          [currentQuestionId]: newAnswerHistory,
        },
      }));
    }
  };

  //
  //Answer shuffling logic
  //

  function GetShuffledAnswerArray() {
    const currentQuestionIndex = examinationState.currentQuestionIndex;
    const answerOrderExists =
      examinationState.answerOrdersById[currentQuestionIndex] != undefined;
    const answerArray = currentQuestion.GetAnswer;
    if (answerOrderExists) {
      const order = examinationState.answerOrdersById[currentQuestionIndex];
      // Sort the answers based on the saved order
      const mapped = order.map((index) =>
        answerArray.find((answer) => answer.answer_serial === index)
      );
      return mapped;
    } else {
      const serials = answerArray.map((answer) => answer.answer_serial);

      for (let i = serials.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [serials[i], serials[j]] = [serials[j], serials[i]];
      }

      examinationState.answerOrdersById[currentQuestionIndex] = serials;

      // Call the function again to apply the shuffled order
      return GetShuffledAnswerArray();
    }
  }

  //
  //Reviewing logic
  //

  function handleReviewCheckboxChecked() {
    const { reviewById } = examinationState;

    const newReviewValue = !reviewById[currentQuestionId];

    const updatedReviewById = {
      ...reviewById,
      [currentQuestionId]: newReviewValue,
    };

    setExaminationState((prevState) => ({
      ...prevState,
      reviewById: updatedReviewById,
    }));
  }

  /*function handleReviewCheckboxCheckedByIndex(index) {
    const { reviewById } = examinationState;

    const newReviewValue = !reviewById[index];

    const updatedReviewById = {
      ...reviewById,
      [index]: newReviewValue,
    };

    setExaminationState((prevState) => ({
      ...prevState,
      reviewById: updatedReviewById,
    }));
  }*/

  function handleMarkCheckboxCheckedByIndex(index) {
    const { markedById } = examinationState;

    const newReviewValue = !markedById[index];

    const updatedMarkedById = {
      ...markedById,
      [index]: newReviewValue,
    };

    setExaminationState((prevState) => ({
      ...prevState,
      markedById: updatedMarkedById,
    }));
  }

  //
  //Question Movement logic and data
  //

  function NextButtonPressed() {
    if (
      (!examinationState.reviewingMarkedQuestions &&
        examinationState.currentQuestionIndex <
          createdExam.QuestionsForTest.length - 1) ||
      (examinationState.reviewingMarkedQuestions &&
        markedKeyIndex + 1 < markedCount)
    ) {
      if (!userData.hide_warning) {
        if (
          !scrollChecks[examinationState.currentQuestionIndex].left ||
          !scrollChecks[examinationState.currentQuestionIndex].right
        ) {
          setWarningsState({ incomplete: false, view: true, end: false });
          return;
        }
        if (
          examinationState.answersById[currentQuestionId] == undefined ||
          examinationState.answersById[currentQuestionId].length == 0
        ) {
          setWarningsState({
            incomplete: true,
            view: false,
            end: false,
          });
          return;
        }
      }
      if (examinationState.reviewingMarkedQuestions) {
        MoveToNextMarkedPage();
      } else if (examinationState.reviewingIncompleteQuestions) {
        MoveToNextUnansweredPage();
      } else {
        if (createdExam.tutorMode && currentQuestionHasAnswers()) {
          //If we are in tutorial mode we show the answer when moving from quesiton to question
          handleViewAnswer();
        } else {
          MoveToNextPage();
        }
      }
    }
  }

  function MoveToNextPage() {
    setExaminationState((prevState) => {
      return {
        ...prevState,
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
      };
    });
  }
  //Marked
  const markedCount = Object.values(examinationState.markedById).filter(
    (value) => value === true
  ).length;

  const markedKeys = Object.entries(examinationState.markedById)
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  const markedKeyIndex = markedKeys.findIndex(
    (key) => key == currentQuestionId
  );

  const nextMarkedKeyIndex = createdExam.QuestionsForTest.findIndex(
    (key) => key == markedKeys[markedKeyIndex + 1]
  );

  const previousMarkedKeyIndex = createdExam.QuestionsForTest.findIndex(
    (key) => key == markedKeys[markedKeyIndex - 1]
  );

  //Unanswered ones
  const emptyKeysUnsorted = examinationState.incompleteToBeReviewed;
  const emptyKeys = createdExam.QuestionsForTest.filter((item) =>
    emptyKeysUnsorted.includes(item)
  );

  const incompleteCount = emptyKeys.length;

  const currentUnasnweredIndex = emptyKeys.findIndex(
    (key) => key == currentQuestionId
  );

  const nextUnansweredKeyIndex = createdExam.QuestionsForTest.findIndex(
    (key) => key == emptyKeys[currentUnasnweredIndex + 1]
  );

  const previousUnasnweredKeyIndex = createdExam.QuestionsForTest.findIndex(
    (key) => key == emptyKeys[currentUnasnweredIndex - 1]
  );

  function MoveToNextUnansweredPage() {
    if (nextUnansweredKeyIndex == -1) {
      //console.error("No more unanswered questions");
      return;
    }
    setExaminationState((prevState) => {
      return {
        ...prevState,
        currentQuestionIndex: nextUnansweredKeyIndex,
      };
    });
  }

  function MoveToPreviousUnansweredPage() {
    if (previousUnasnweredKeyIndex == -1) {
      //console.error("No more unanswered questions");
      return;
    }
    setExaminationState((prevState) => {
      return {
        ...prevState,
        currentQuestionIndex: previousUnasnweredKeyIndex,
      };
    });
  }

  function MoveToNextMarkedPage() {
    if (nextMarkedKeyIndex == -1) {
      //console.log("No more marked questions");
      return;
    }
    setExaminationState((prevState) => {
      return {
        ...prevState,
        currentQuestionIndex: nextMarkedKeyIndex,
      };
    });
  }

  function MoveToPreviousMarkedPage() {
    if (previousMarkedKeyIndex == -1) {
      //console.log("No more marked questions");
      return;
    }
    setExaminationState((prevState) => {
      return {
        ...prevState,
        currentQuestionIndex: previousMarkedKeyIndex,
      };
    });
  }

  function MoveToReviewMode() {
    setExaminationState((prevState) => {
      return {
        ...prevState,
        reviewMode: true,
      };
    });
  }

  //
  //Tutor mode functionality
  //
  function handleViewAnswer() {
    const viewedQuestions = [...examinationState.tutorialMode];
    if (!viewedQuestions.includes(currentQuestionId)) {
      viewedQuestions.push(currentQuestionId);
    }
    setExaminationState((prevState) => ({
      ...prevState,
      viewAnswerMode: true,
      tutorialMode: [...viewedQuestions],
    }));
  }

  function CloseViewedAnswer() {
    //Close the answer
    setExaminationState((prevState) => ({
      ...prevState,
      viewAnswerMode: false,
    }));
    if (
      examinationState.currentQuestionIndex <
      createdExam.QuestionsForTest.length - 1
    ) {
      //Move to next page if there is one
      MoveToNextPage();
    } else {
      //Start the review if there isn't one
      MoveToReviewMode();
    }
  }

  //
  //End Exam logic
  //

  async function SaveAndendExam() {
    const result = await gardeTest({
      testData: { guid: createdExam.guid },
    }).unwrap();
    const prevData =
      result.CompletedTests[
        result.CompletedTests.findIndex(
          (completedExam) => completedExam.guid == createdExam.guid
        )
      ];
    const examToReview = {
      test: result,
      guid: createdExam.guid,
      prevData,
    };
    dispatch(
      setReviewedExam({
        examToReview: examToReview,
      })
    );
    dispatch(hideSidebar());
    const reviewObject = result.ReviewHighYield;
    dispatch(setReviewHighYield({ ...reviewObject }));
    navigate("/completetests");
  }

  function AddMoreTime() {
    const milisecondsWhenTestRanOutOfTime = whenTestRanOutOfTime;
    //Figure out how much time has passed since the target time was reached we're doing this because the button press on the add more time might not be immediate
    const currentTime = Date.now(); // Current time in milliseconds
    const initialTimeDifference = Math.floor(
      (currentTime - milisecondsWhenTestRanOutOfTime) / 1000
    ); // Convert to seconds
    //Add five minutes on top of that
    const fiveMinutesSeconds = 300;

    //Add the time
    dispatch(
      addTestExtraSeconds({
        testExtraSeconds: fiveMinutesSeconds + initialTimeDifference,
      })
    );
  }

  //
  //Utility checkers
  //

  function currentQuestionHasAnswers() {
    return (
      examinationState.answersById[currentQuestionId] != undefined &&
      examinationState.answersById[currentQuestionId].length != 0
    );
  }

  return (
    <div className="flex flex-col bg-secondaryLight">
      {/* Top nav bar */}
      <div className="flex justify-between bg-primaryDark p-2 font-bold text-contrastText sm:h-min select-none">
        <div className="flex flex-col">
          <span>OSCE Dental</span>
          <span className="text-red-600">
            {examinationState.reviewingAllQuestions &&
              "Reviewing All Questions"}
            {examinationState.reviewingMarkedQuestions &&
              "Reviewing Marked Questions"}
            {examinationState.reviewingIncompleteQuestions &&
              "Reviewing Incomplete Questions"}
          </span>
        </div>
        {!examinationState.reviewMode && (
          <div className="flex flex-col">
            <ExamTimer
              currentQuestionIndex={examinationState.currentQuestionIndex}
              isLoading={
                isLoading ||
                isFetching ||
                isModelLoading ||
                questionImageFirstLoad ||
                questionImageLoading
              }
              numberOfQuestions={createdExam.QuestionsForTest.length}
            />
            {!examinationState.reviewingMarkedQuestions &&
              !examinationState.reviewingIncompleteQuestions && (
                <div className="text-right sm:text-left">
                  Question: {examinationState.currentQuestionIndex + 1} of{" "}
                  {maxQuestions}{" "}
                </div>
              )}
            {examinationState.reviewingMarkedQuestions && (
              <div className="text-right sm:text-left">
                Question: {markedKeyIndex + 1} of {markedCount}{" "}
              </div>
            )}
            {examinationState.reviewingIncompleteQuestions && (
              <div className="text-right sm:text-left">
                Question: {currentUnasnweredIndex + 1} of {incompleteCount}{" "}
              </div>
            )}
          </div>
        )}
        {examinationState.reviewMode && (
          <div className="flex flex-col h-[60px] justify-center">
            <ExamTimer
              currentQuestionIndex={examinationState.currentQuestionIndex}
              isLoading={isLoading}
              numberOfQuestions={createdExam.QuestionsForTest.length}
            />
          </div>
        )}
      </div>
      {/* mid block */}
      {!examinationState.reviewMode && (
        <div className="bg-secondaryLight flex flex-col items-center">
          {/* actions */}
          {isMobile ? (
            <div className="flex justify-end items-start w-full bg-secondaryLight p-2 h-min select-none">
              <div className="sm:hidden flex justify-between items-center">
                <button
                  className="text-primaryDark"
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <Menu className="w-6 h-6" />
                </button>

                {isMobileMenuOpen && (
                  <div className="fixed top-0 left-0 right-0 bg-secondaryLight p-7 shadow-lg h-screen w-screen flex flex-col gap-5 overflow-y-auto">
                    <div className="flex flex-row justify-between">
                      <img src={"images/dental.png"} className="w-[100px]" />
                      <button
                        className="flex space-x-1 text-primaryDark mb-2"
                        onClick={() => {
                          setIsMobileMenuOpen(false);
                        }}
                      >
                        <X className="w-6 bg-white rounded" />
                      </button>
                    </div>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={() => {
                        setNotesOpen(true);
                        setTextEditModes({
                          highlight: false,
                          strikeThrough: false,
                        });
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      <NotepadText className="w-5" />
                      <span className="font-normal">Notes</span>
                    </button>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4 "
                      onClick={() => {
                        setTextEditModes({
                          highlight: !textEditModes.highlight,
                          strikeThrough: false,
                        });
                      }}
                    >
                      <Square className="bg-yellow-300 text-yellow-300 border-2 border-black w-[16px] h-[16px] mt-1" />
                      <Pen className="w-[14px] h-[14px] mt-1" />
                      <span className="font-normal pl-2">
                        {textEditModes.highlight
                          ? "Highlight enabled"
                          : "Highlight"}
                      </span>
                    </button>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={() => {
                        setTextEditModes({
                          highlight: false,
                          strikeThrough: !textEditModes.strikeThrough,
                        });
                      }}
                    >
                      <Strikethrough className="w-[18px] h-[18px] mt-1 flex-shrink-0" />
                      <span className="font-normal">
                        {textEditModes.strikeThrough
                          ? "Strike through enabled"
                          : "Strike through"}
                      </span>
                    </button>
                    {createdExam.tutorMode && (
                      <button
                        className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4 "
                        onClick={() => {
                          handleViewAnswer();
                        }}
                      >
                        <span className="font-normal">View Answer</span>
                      </button>
                    )}
                    <label
                      htmlFor="leftSide"
                      className="text-primaryDark font-bold"
                    >
                      {isMobile ? "Top Size:" : "Left Size"}
                    </label>
                    <select
                      name="leftSide"
                      id="leftSide"
                      className="h-10"
                      value={textSize.left}
                      onChange={(event) => {
                        setTextSize({
                          left: event.target.value,
                          right: textSize.right,
                        });
                      }}
                    >
                      <option value="text-xs">x-small</option>
                      <option value="text-sm">small</option>
                      <option value="text-base">medium</option>
                      <option value="text-lg">large</option>
                      <option value="text-xl">x-large</option>
                    </select>
                    <label
                      htmlFor="rightSide"
                      className="text-primaryDark font-bold"
                    >
                      {isMobile ? "Bottom Size:" : "Right Size"}
                    </label>
                    <select
                      name="rightSide"
                      id="rightSide"
                      value={textSize.right}
                      className="h-10"
                      onChange={(event) => {
                        setTextSize({
                          left: textSize.left,
                          right: event.target.value,
                        });
                      }}
                    >
                      <option value="text-xs">x-small</option>
                      <option value="text-sm">small</option>
                      <option value="text-base">medium</option>
                      <option value="text-lg">large</option>
                      <option value="text-xl">x-large</option>
                    </select>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={handleReviewCheckboxChecked}
                    >
                      <Checkbox
                        checked={
                          !!examinationState.reviewById[currentQuestionId]
                        }
                        setChecked={handleReviewCheckboxChecked}
                        text={undefined}
                      />
                      <PenSquareIcon className="text-contrastText" />
                      <span className="text-contrastText font-normal">
                        Mark for review
                      </span>
                    </button>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={() => {
                        setResourcesOpen(true);
                      }}
                    >
                      <BookCopy />
                      <span>Resources</span>
                    </button>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={() => {
                        setFeedback({ open: true, feedback: "", result: "" });
                      }}
                    >
                      <Megaphone />
                      <span>Feedback</span>
                    </button>
                    <button
                      className="flex space-x-1 text-contrastText mb-2 space-x-3 bg-primaryDark rounded p-4"
                      onClick={() => {
                        setExitExamOpen(true);
                      }}
                    >
                      <DoorOpen />
                      <span>Exit Test</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className={`grid ${
                createdExam.tutorMode ? "grid-cols-7" : "grid-cols-6"
              } gap-4 justify-between items-start w-full bg-secondaryLight p-2 h-min select-none`}
            >
              <div className="col-span-3 flex gap-3">
                <button
                  className="flex col-span-1 space-x-1 text-primaryDark"
                  onClick={() => {
                    setNotesOpen(true);
                    setTextEditModes({
                      highlight: false,
                      strikeThrough: false,
                    });
                  }}
                >
                  <NotepadText className="w-4" />
                  <span className="font-bold">Notes</span>
                </button>
                <button
                  className="flex col-span-1 row space-x-1 text-primaryDark"
                  onClick={() => {
                    setTextEditModes({
                      highlight: !textEditModes.highlight,
                      strikeThrough: false,
                    });
                  }}
                >
                  <Square className="bg-yellow-300 text-yellow-300 border-2 border-black w-[16px] h-[16px] mt-1" />
                  <Pen className="w-[14px] h-[14px] mt-1" />
                  <span className="font-bold">
                    {textEditModes.highlight
                      ? "Highlight enabled"
                      : "Highlight"}
                  </span>
                </button>
                <button
                  className="flex col-span-1 space-x-1 text-primaryDark"
                  onClick={() => {
                    setTextEditModes({
                      highlight: false,
                      strikeThrough: !textEditModes.strikeThrough,
                    });
                  }}
                >
                  <Strikethrough className="w-[14px] h-[14px] mt-1 flex-shrink-0" />
                  <span className="font-bold">
                    {textEditModes.strikeThrough
                      ? "Strike through enabled"
                      : "Strike through"}
                  </span>
                </button>
              </div>
              {/* view answer button */}
              {createdExam.tutorMode && (
                <div className="col-span-1 sm:flex justify-center text-primaryDark">
                  <button
                    className="flex col-span-1 space-x-1 text-primaryDark mr-2"
                    onClick={() => {
                      handleViewAnswer();
                    }}
                  >
                    <span className="font-bold">View Answer</span>
                  </button>
                </div>
              )}
              <div className="col-span-3 sm:flex justify-end text-primaryDark">
                <button
                  className="flex col-span-1 space-x-1 text-primaryDark mr-2"
                  onClick={() => {
                    setTextSettingsOpen(true);
                  }}
                >
                  <Cog className="w-4" />
                  <span className="font-bold">Text Settings</span>
                </button>
                <button className="flex col-span-1 justify-self-end space-x-1">
                  <Checkbox
                    checked={!!examinationState.reviewById[currentQuestionId]}
                    setChecked={() => {
                      const { reviewById } = examinationState;

                      const newReviewValue = !reviewById[currentQuestionId];

                      const updatedReviewById = {
                        ...reviewById,
                        [currentQuestionId]: newReviewValue,
                      };

                      setExaminationState((prevState) => ({
                        ...prevState,
                        reviewById: updatedReviewById,
                      }));
                    }}
                    text={undefined}
                  />

                  <span className="text-primaryDark font-bold">
                    Mark for review
                  </span>
                </button>
              </div>
            </div>
          )}
          {/* Center columns */}
          <div className="bg-primaryBackground w-[99vw] flex flex-col justify-center">
            <div className="bg-primaryBackground m-2 border border-gray-400">
              <TestCenterColumns
                centerColumSize={centerColumSize}
                currentQuestion={currentQuestion}
                textSize={textSize}
                getHighlightedText={getHighlightedText}
                currentQuestionImage={imageSource}
                questionLoading={isFetching || isLoading}
                questionImageLoading={
                  questionImageLoading || questionImageFirstLoad
                }
                processQuestionText={processQuestionText}
                GetShuffledAnswerArray={GetShuffledAnswerArray}
                setExaminationState={setExaminationState}
                examinationState={examinationState}
                createdExam={createdExam}
                handleAnswerChecked={handleAnswerChecked}
                processQuestionLabel={processQuestionLabel}
                setScrollChecks={setScrollChecks}
                ScrollChecks={scrollChecks}
                questionType={currentQuestion?.GetQuestion?.question_type}
                imagePath={currentQuestion?.GetQuestion?.image_path}
                currentQuestionId={currentQuestionId}
                completedAnswerArray={undefined}
                showExplanations={undefined}
                showChecksAndXes={undefined}
                ShowMyAnswerMode={undefined}
                reviewData={undefined}
              />
            </div>
          </div>
        </div>
      )}
      {examinationState.reviewMode && (
        <div className="bg-secondaryLight text-primaryText flex flex-col items-center">
          {/* Center column for review */}
          <div className="bg-secondaryLight w-[98vw] flex flex-col justify-center">
            <div className="bg-primaryBackground m-2 ">
              <div
                className={`flex flex-col`}
                style={{ height: centerColumSizeReview }}
              >
                <div className="mt-3 ml-2 font-semibold text-2xl">
                  <span>Review Screen</span>
                </div>
                <div className="bg-secondaryLight mx-2 px-3 text-xl">
                  <span>Instructions</span>
                </div>
                <div className="bg-secondaryBackground mx-2 mt-2 px-3 text-md font-light flex flex-col space-y-3">
                  <span>
                    Below is a summary of your answers. You can review your
                    questions in 3 different ways.
                  </span>
                  <span>
                    The buttons in the lower right-hand corner correspond to
                    these choices:
                  </span>
                  <ol className="list-decimal pl-5">
                    <li>
                      Review All - Go through all of your questions and answers.
                    </li>
                    <li>
                      Review Incomplete - Revisit the questions that are
                      incomplete.
                    </li>
                    <li>
                      Review Marked - Return to the questions that are marked
                      for review. (Click the checkbox to mark or unmark a
                      question.)
                    </li>
                  </ol>
                  <span>
                    You may also click on a question number to jump to it.
                  </span>
                  <span>
                    When you have finished your review, click on the End Review
                    button. Note that clicking on this button will end the exam
                    and you will no longer be able to return to the Review
                    Screen or any of the previous screens
                  </span>
                </div>
                <div className="bg-secondaryLight mx-2 mt-2 px-3 text-xl">
                  <span>Section</span>
                </div>
                <div className="grid grid-cols-3 gap-x-3 gap-y-1 mt-2 mx-2 overflow-y-auto max-h-min">
                  {createdExam.QuestionsForTest.map((key, index) => (
                    <div
                      className="bg-secondaryExtraLight text-left px-2 h-[30px] text-blue-900 flex space-x-3"
                      key={`question-${key}`}
                    >
                      <CheckBox
                        checked={examinationState.markedById[key]}
                        setChecked={() => {
                          handleMarkCheckboxCheckedByIndex(key);
                        }}
                        text={undefined}
                      />
                      <button
                        key={key}
                        className="underline text-primaryText"
                        onClick={() => {
                          setExaminationState((prevState) => {
                            return {
                              ...prevState,
                              reviewMode: false,
                              currentQuestionIndex: index,
                            };
                          });
                        }}
                      >
                        {`Question ${index + 1}`}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {examinationState.reviewMode &&
        examinationState.reviewingAllQuestions && (
          <div className="bg-secondaryLight flex flex-col items-center"></div>
        )}
      {/* Bottom Bar */}
      {!examinationState.reviewMode && (
        <div
          className="grid grid-cols-4 w-full flex-grow h-full grid-cols-[1fr_0.7fr_1fr_1fr] bg-secondaryLight overflow-y-hidden"
          style={{ paddingBottom: 4 }}
        >
          <div className="flex space-x-3 items-start mb-3 md:mb-0 ml-0 sm:ml-3 pt-1">
            {(examinationState.currentQuestionIndex ==
              createdExam.QuestionsForTest.length - 1 ||
              examinationState.reviewingAllQuestions ||
              examinationState.reviewingMarkedQuestions) &&
              !isMobile && (
                <button
                  className="bg-primaryDark text-contrastText font-medium text-sm px-1 h-8 w-full md:w-auto flex items-center"
                  onClick={() => {
                    if (createdExam.tutorMode && currentQuestionHasAnswers()) {
                      handleViewAnswer();
                    } else {
                      MoveToReviewMode();
                    }
                  }}
                >
                  <span>Review and Submit</span>
                </button>
              )}
            {!isMobile && (
              <button
                className="bg-secondaryDark text-contrastText font-medium text-sm px-5 h-8 w-auto flex items-center"
                onClick={() => {
                  setResourcesOpen(true);
                }}
              >
                <span>Resources</span>
              </button>
            )}
          </div>
          <div className="flex space-x-3 items-start mb-3 md:mb-0 ml-0 sm:ml-3 pt-1">
            {!isMobile && (
              <button
                className="bg-secondaryDark text-contrastText font-medium text-sm px-5 h-8 w-auto flex items-center"
                onClick={() => {
                  setExitExamOpen(true);
                }}
              >
                <span>Exit Test</span>
              </button>
            )}
          </div>
          {!isMobile && (
            <div className="flex space-x-3 items-start mb-3 md:mb-0 ml-0 pt-1">
              <button
                className="bg-primaryDark text-contrastText font-medium text-sm px-5 h-8 w-auto flex items-center"
                onClick={() => {
                  setFeedback({ open: true, feedback: "", result: "" });
                }}
              >
                <span>Feedback</span>
              </button>
            </div>
          )}
          <div className="flex space-x-3 items-start justify-self-end md:flex-none mr-0 sm:mr-3 pt-1">
            <button
              className="bg-primaryDark text-contrastText font-medium text-sm px-1 h-8 flex items-center justify-center w-[100px]"
              onClick={() => {
                if (examinationState.reviewingMarkedQuestions) {
                  MoveToPreviousMarkedPage();
                  return;
                } else if (examinationState.reviewingIncompleteQuestions) {
                  MoveToPreviousUnansweredPage();
                  return;
                }
                if (examinationState.currentQuestionIndex >= 1) {
                  setExaminationState((prevState) => {
                    return {
                      ...prevState,
                      currentQuestionIndex: prevState.currentQuestionIndex - 1,
                    };
                  });
                }
              }}
            >
              <ChevronLeft />
              <span className="mr-4">Previous</span>
            </button>
            <div>
              {" "}
              {(examinationState.currentQuestionIndex ==
                createdExam.QuestionsForTest.length - 1 ||
                examinationState.reviewingAllQuestions ||
                examinationState.reviewingMarkedQuestions) &&
                isMobile && (
                  <button
                    className="bg-primaryDark text-contrastText font-medium text-sm px-1 h-8 w-full md:w-auto flex items-center mb-2"
                    onClick={() => {
                      {
                        setExaminationState((prevState) => {
                          return {
                            ...prevState,
                            reviewMode: true,
                          };
                        });
                      }
                    }}
                  >
                    <span>Review and Submit</span>
                  </button>
                )}
              <button
                className="bg-secondaryDark text-contrastText font-medium text-sm px-6 h-8 w-full md:w-auto flex items-center"
                onClick={() => {
                  setNavigator({ open: true });
                }}
              >
                <span>Navigator</span>
              </button>
            </div>
            <button
              className={`${
                (examinationState.reviewingMarkedQuestions &&
                  markedKeyIndex + 1 < markedCount) ||
                examinationState.currentQuestionIndex <
                  createdExam.QuestionsForTest.length - 1
                  ? "bg-primaryDark text-contrastText"
                  : "bg-secondaryDark text-gray-500"
              } font-medium text-sm px-1 h-8 flex items-center justify-end w-[100px]`}
              onClick={NextButtonPressed}
            >
              <span className="mr-3 ml-5">Next</span>
              <ChevronRight className="justify-self-end" />
            </button>
          </div>
        </div>
      )}
      {examinationState.reviewMode && (
        <div
          className="grid grid-cols-4 w-full flex-grow h-full grid-cols-[1fr_1fr] bg-secondaryLight overflow-y-hidden"
          style={{ paddingBottom: 4 }}
        >
          <div className="flex space-x-3 items-start mb-3 md:mb-0 ml-0 sm:ml-3 pt-1">
            <button
              className="bg-red-500 text-contrastText font-medium text-sm px-5 h-8 w-auto flex items-center"
              onClick={() => {
                setWarningsState({ incomplete: false, view: false, end: true });
              }}
            >
              <span>End Review</span>
            </button>
          </div>
          <div className="flex space-x-3 items-start justify-self-end md:flex-none mr-0 sm:mr-3 pt-1">
            <button
              className="bg-secondaryDark text-contrastText font-medium text-sm px-6 h-8 w-full md:w-auto flex items-center"
              onClick={() => {
                if (
                  Object.values(examinationState.answersById).some(
                    // @ts-expect-error. this will always be an array
                    (value) => value.length == 0
                  )
                ) {
                  const emptyKeysAsString = Object.keys(
                    examinationState.answersById
                  ).filter(
                    (key) =>
                      Array.isArray(examinationState.answersById[key]) &&
                      examinationState.answersById[key].length === 0
                  );
                  const convertedEmptyStrings = emptyKeysAsString.map(Number);

                  const emptyKeys = createdExam.QuestionsForTest.filter(
                    (item) => convertedEmptyStrings.includes(item)
                  );
                  const firstIncomplete =
                    createdExam.QuestionsForTest.findIndex((key) =>
                      emptyKeys.includes(key)
                    );

                  setExaminationState((prevState) => {
                    return {
                      ...prevState,
                      reviewMode: false,
                      reviewingAllQuestions: false,
                      reviewingMarkedQuestions: false,
                      reviewingIncompleteQuestions: true,
                      currentQuestionIndex: firstIncomplete,
                      incompleteToBeReviewed: convertedEmptyStrings,
                    };
                  });
                } else {
                  toast("There are no incomplete questions!");
                }
              }}
            >
              <span>Review incomplete</span>
            </button>
            <button
              className="bg-secondaryDark text-contrastText font-medium text-sm px-6 h-8 w-full md:w-auto flex items-center"
              onClick={() => {
                setExaminationState((prevState) => {
                  return {
                    ...prevState,
                    reviewMode: false,
                    reviewingAllQuestions: true,
                    reviewingMarkedQuestions: false,
                    reviewingIncompleteQuestions: false,
                    currentQuestionIndex: 0,
                  };
                });
              }}
            >
              <span>Review All</span>
            </button>
            <button
              className="bg-secondaryDark text-contrastText font-medium text-sm px-6 h-8 w-full md:w-auto flex items-center"
              onClick={() => {
                if (
                  Object.values(examinationState.markedById).some(
                    (value) => value === true
                  )
                ) {
                  const firstTrueKey = Object.entries(
                    examinationState.markedById
                  ).find(([, value]) => value === true)?.[0];

                  const matchingIndex = createdExam.QuestionsForTest.findIndex(
                    (question) => question == firstTrueKey
                  );
                  setExaminationState((prevState) => {
                    return {
                      ...prevState,
                      reviewMode: false,
                      reviewingAllQuestions: false,
                      reviewingMarkedQuestions: true,
                      reviewingIncompleteQuestions: false,
                      currentQuestionIndex: matchingIndex,
                    };
                  });
                } else {
                  toast("There are no marked questions!");
                }
              }}
            >
              <span>Review Marked</span>
            </button>
          </div>
        </div>
      )}
      {/* Notes modal */}
      <Modal
        isOpen={notesOpen}
        onClose={() => {
          setNotesOpen(false);
        }}
        title={"Notes"}
        submit={undefined}
        onSubmit={undefined}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <textarea
          className="border border-gray-300 p-2 w-full mb-4 h-60 resize-none"
          placeholder="Notes..."
          onChange={handleNotesChange}
          value={
            examinationState.notesById[currentQuestionId]
              ? examinationState.notesById[currentQuestionId]
              : ""
          }
          autoComplete="off"
        />
      </Modal>
      {/* Question feedback */}
      <Modal
        isOpen={feedback.open}
        onClose={() => {
          setFeedback({ open: false, feedback: "", result: "" });
          feedbackReset();
        }}
        title={`Feedback for question ${
          examinationState.currentQuestionIndex + 1
        }`}
        submit={!feedback.result}
        onSubmit={async () => {
          const result = await submitFeedback({
            question_id: currentQuestionId,
            feedback: feedback.feedback,
          }).unwrap();
          setFeedback({
            open: feedback.open,
            feedback: "",
            result: result.message,
          });
        }}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        {feedback.result || feedbackIsSuccess ? (
          <div className="mb-2">
            <span>{feedback.result}</span>
          </div>
        ) : feedbackIsLoading ? (
          <div>
            <span>Sending feedback...</span>
          </div>
        ) : (
          <textarea
            className="border border-gray-300 p-2 w-full mb-4 h-60 resize-none bg-primaryBackground text-primaryText"
            placeholder="Tell us if you have any thoughts about this question..."
            onChange={(event) => {
              setFeedback({
                open: true,
                feedback: event.target.value,
                result: "",
              });
            }}
            value={feedback.feedback}
            autoComplete="off"
          />
        )}
      </Modal>
      {/* Text Size Modal */}
      <Modal
        isOpen={textSettingsOpen}
        onClose={() => {
          setTextSettingsOpen(false);
        }}
        title={
          <div className="flex">
            <span className="mr-2">Text Size</span>
            <ToolTip
              tip={"This is option is not present in the real exam"}
              width={undefined}
            />
          </div>
        }
        submit={undefined}
        onSubmit={undefined}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <div className="mr-2 flex flex-row mb-2">
          <label htmlFor="leftSide">
            {isMobile ? "Top Size:" : "Left Size"}
          </label>
          <select
            name="leftSide"
            id="leftSide"
            value={textSize.left}
            onChange={(event) => {
              setTextSize({
                left: event.target.value,
                right: textSize.right,
              });
            }}
          >
            <option value="text-xs">x-small</option>
            <option value="text-sm">small</option>
            <option value="text-base">medium</option>
            <option value="text-lg">large</option>
            <option value="text-xl">x-large</option>
          </select>
          <label htmlFor="rightSide">
            {isMobile ? "Bottom Size:" : "Right Size"}
          </label>
          <select
            name="rightSide"
            id="rightSide"
            value={textSize.right}
            onChange={(event) => {
              setTextSize({
                left: textSize.left,
                right: event.target.value,
              });
            }}
          >
            <option value="text-xs">x-small</option>
            <option value="text-sm">small</option>
            <option value="text-base">medium</option>
            <option value="text-lg">large</option>
            <option value="text-xl">x-large</option>
          </select>
        </div>
      </Modal>
      {/* Navigator */}
      <Modal
        isOpen={navigator.open}
        onClose={() => {
          setNavigator({ open: false });
        }}
        title={"Navigator"}
        submit={undefined}
        onSubmit={undefined}
        fullWidth={true}
        closeHidden={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <QuestionNavigator
          onClose={() => {
            setNavigator({ open: false });
          }}
          questionsIdData={createdExam.QuestionsForTest}
          examinationState={examinationState}
          NavigateToQuestion={(index) => {
            setExaminationState((prevState) => ({
              ...prevState,
              currentQuestionIndex: index,
            }));

            setExaminationState((prevState) => {
              return {
                ...prevState,
                currentQuestionIndex: index,
              };
            });
            setNavigator({ open: false });
          }}
          showNotes={() => {
            setNotesOpen(true);
          }}
        />
      </Modal>
      {/* Resources */}
      <Modal
        isOpen={resourcesOpen}
        onClose={() => {
          setResourcesOpen(false);
        }}
        title={"Resources"}
        cancelText={"Close"}
        pixelWidth={500}
        submit={undefined}
        closeHidden={undefined}
        onSubmit={undefined}
        fullWidth={undefined}
        submitText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <UniChart />
      </Modal>
      {/*Incomplete warning modal*/}
      <Modal
        isOpen={warningsState.incomplete}
        onClose={() => {
          setWarningsState({
            incomplete: false,
            view: false,
            end: false,
          });
          MoveToNextPage();
        }}
        onSubmit={() => {
          setWarningsState({ incomplete: false, view: false, end: false });
        }}
        title={"Warning"}
        submit={true}
        closeHidden={false}
        submitText={"No"}
        cancelText={"Yes"}
        buttonsSpaced={true}
        xClose={true}
        closeDark={true}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitDark={undefined}
      >
        <div className="border-t-2 border-b-2 border-gray-400 mb-4 mt-2 pt-3 pb-3">
          <div className="flex space-x-2 h-[100px] items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth="1.5"
              className="min-w-[40px] w-[40px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2L2 22h20L12 2z"
              />
              <line
                x1="12"
                y1="8"
                x2="12"
                y2="14"
                stroke="black"
                strokeWidth="2"
              />
              <circle cx="12" cy="18" r="1" fill="black" />
            </svg>
            <span>{Warnings.Incomplete}</span>
          </div>
        </div>
      </Modal>
      {/*scroll warning modal*/}
      <Modal
        isOpen={warningsState.view}
        onSubmit={() => {}}
        onClose={() => {
          setWarningsState({ incomplete: false, view: false, end: false });
        }}
        title={"Warning"}
        submit={false}
        closeHidden={false}
        submitText={"No"}
        cancelText={"OK"}
        buttonsSpaced={true}
        xClose={true}
        closeDark={true}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitDark={undefined}
      >
        <div className="border-t-2 border-b-2 border-gray-400 mb-4 mt-2 pt-3 pb-3">
          <div className="flex space-x-2 h-[100px] items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth="1.5"
              className="min-w-[40px] w-[40px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2L2 22h20L12 2z"
              />
              <line
                x1="12"
                y1="8"
                x2="12"
                y2="14"
                stroke="black"
                strokeWidth="2"
              />
              <circle cx="12" cy="18" r="1" fill="black" />
            </svg>
            <span>{Warnings.View}</span>
          </div>
        </div>
      </Modal>
      {/*review warning modal*/}
      <Modal
        isOpen={warningsState.end}
        onClose={async () => {
          await SaveAndendExam();
        }}
        onSubmit={() => {
          setWarningsState({ incomplete: false, view: false, end: false });
        }}
        title={"Warning"}
        submit={true}
        closeHidden={false}
        submitText={"No"}
        cancelText={"Yes"}
        buttonsSpaced={true}
        xClose={true}
        closeDark={true}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitDark={undefined}
      >
        <div className="border-t-2 border-b-2 border-gray-400 mb-4 mt-2 pt-3 pb-3">
          <div className="flex space-x-2 h-[120px] items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth="1.5"
              className="min-w-[40px] w-[40px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2L2 22h20L12 2z"
              />
              <line
                x1="12"
                y1="8"
                x2="12"
                y2="14"
                stroke="black"
                strokeWidth="2"
              />
              <circle cx="12" cy="18" r="1" fill="black" />
            </svg>
            <span>{Warnings.End}</span>
          </div>
        </div>
      </Modal>
      {/*View Answer Modal*/}
      <Modal
        isOpen={examinationState.viewAnswerMode}
        onSubmit={CloseViewedAnswer}
        onClose={CloseViewedAnswer}
        title={"Viewing Answer"}
        submit={true}
        closeHidden={true}
        submitText={"Close"}
        buttonsSpaced={false}
        xClose={true}
        closeDark={true}
        fullWidth={true}
        pixelWidth={undefined}
        cancelText={undefined}
        submitDark={undefined}
      >
        <div className="border-t-2 border-b-2 border-gray-400 mb-4 mt-2 pt-3 pb-3">
          <TestCenterColumns
            centerColumSize={centerColumSize}
            currentQuestion={currentQuestion}
            textSize={textSize}
            getHighlightedText={getHighlightedText}
            currentQuestionImage={imageSource}
            questionImageLoading={
              questionImageLoading || questionImageFirstLoad
            }
            processQuestionText={processQuestionText}
            GetShuffledAnswerArray={GetShuffledAnswerArray}
            examinationState={examinationState}
            createdExam={createdExam}
            handleAnswerChecked={handleAnswerChecked}
            processQuestionLabel={processQuestionLabel}
            setScrollChecks={setScrollChecks}
            ScrollChecks={scrollChecks}
            questionType={currentQuestion?.GetQuestion?.question_type}
            imagePath={currentQuestion?.GetQuestion?.image_path}
            completedAnswerArray={
              examinationState.answersById[currentQuestionId]
            }
            showExplanations={true}
            showChecksAndXes={true}
            setExaminationState={undefined}
            currentQuestionId={undefined}
            ShowMyAnswerMode={undefined}
            reviewData={undefined}
            questionLoading={
              isFetching || isLoading || currentQuestion == undefined
            }
          />
        </div>
      </Modal>
      {/*Timer ended Modal*/}
      <Modal
        isOpen={testOutOfTime}
        onSubmit={() => {
          //Add more time
          AddMoreTime();
        }}
        onClose={async () => {
          //End exam
          await SaveAndendExam();
        }}
        title={"Out of time"}
        submitText={"Add Time"}
        cancelText={"End Exam"}
        buttonsSpaced={true}
        closeDark={true}
        submit={true}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        xClose={undefined}
        submitDark={undefined}
      >
        <div className="border-t-2 border-b-2 border-gray-400 mb-4 mt-2 pt-3 pb-3">
          <span>
            You have run out of time, would you like to end your exam now Or add
            more time?
          </span>
        </div>
      </Modal>
      <ExamSaver
        examinationState={{ ...examinationState }}
        createdExam={{ ...createdExam }}
        numberOfQuestions={createdExam.QuestionsForTest.length}
        navigatorOpen={navigator.open}
        setErrorStatusCode={setErrorStatusCode}
        exitExamOpen={exitExamOpen}
        setExitExamOpen={setExitExamOpen}
        aboutToEndExam={examinationState.reviewMode}
        outOfTimeOpen={testOutOfTime}
        questionType={currentQuestion?.GetQuestion?.question_type}
      />
    </div>
  );
}

export default Test;
