import { useEffect, useRef } from "react";
import ModelDisplayer from "../modelDisplayer/modeldisplayer";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/login.slice";
import CAPatientTable from "../CAPatientTable/CApatienttable";
import USPatientTable from "../USPatientTable/uspatienttable";
import PeridontalChart from "../PeriodontalChart/PeriodontalChartLingual";
import Snbchart from "../snbChart/snbchart";
import DentalExaminationChart from "../dentalExaminationChart/dentalexaminationchart";
import MoyerPredictionChart from "../moyerPredictionChart/moyerpredictionchart";
import MoyerInputChart from "../moyerinputchart/moyerinputctart";
import MultipleChoiceAnswerBlock from "../multipleChoiceAnswerBlock/multipleChoiceAnswerBlock";
import PerscriptionAnswerBlock from "../PerscriptionAnswerBlock/perscriptionAnswerBlock";
import { ErrorBoundary } from "react-error-boundary";
import { AlertCircle } from "lucide-react";

/*
const ExamLoadingSpinner = ({ centerColumSize }) => {
  return (
    <div
      className={`bg-primaryBackground flex justify-center items-center`}
      style={{ height: centerColumSize }}
    >
      <div role="status">
        <svg
          aria-hidden="true"
          className="inline w-64 h-64 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
*/
const TestCenterColumns = ({
  centerColumSize,
  currentQuestion,
  textSize,
  getHighlightedText,
  currentQuestionImage,
  questionLoading,
  questionImageLoading,
  processQuestionText,
  GetShuffledAnswerArray,
  setExaminationState,
  examinationState,
  createdExam,
  handleAnswerChecked,
  processQuestionLabel,
  setScrollChecks,
  ScrollChecks,
  completedAnswerArray,
  questionType,
  imagePath,
  showExplanations,
  showChecksAndXes,
  currentQuestionId,
  ShowMyAnswerMode,
  reviewData,
}) => {
  const range = 10;

  //
  //Hooks
  //
  const userData = useSelector(getUserData);
  const DLOSCEMode = userData.exam_type;
  const scrollableDivRefLeft = useRef(null);
  const scrollableDivRefRight = useRef(null);
  //
  //Functionality
  //
  const handleScrollLeft = () => {
    const divLeft = scrollableDivRefLeft.current;
    if (divLeft) {
      //divLeft.scrollHeight - divLeft.scrollTop === divLeft.clientHeight;
      const isScrolledToBottomLeft =
        Math.abs(
          divLeft.scrollHeight - divLeft.scrollTop - divLeft.clientHeight
        ) <= range;

      if (isScrolledToBottomLeft) {
        if (setScrollChecks != undefined) {
          setScrollChecks((prevState) => {
            const newState = [...prevState];
            newState[examinationState.currentQuestionIndex] = {
              ...newState[examinationState.currentQuestionIndex],
              left: true,
            };
            return newState;
          });
        }
      }
    }
  };

  const handleScrollRight = () => {
    const divRight = scrollableDivRefRight.current;
    if (divRight) {
      const isScrolledToBottomRight =
        Math.abs(
          divRight.scrollHeight - divRight.scrollTop - divRight.clientHeight
        ) <= range;

      //divRight.scrollHeight - divRight.scrollTop === divRight.clientHeight;
      if (isScrolledToBottomRight) {
        if (setScrollChecks != undefined) {
          setScrollChecks((prevState) => {
            const newState = [...prevState];
            newState[examinationState.currentQuestionIndex] = {
              ...newState[examinationState.currentQuestionIndex],
              right: true,
            };
            return newState;
          });
        }
      }
    }
  };

  //
  //useEffect
  //
  useEffect(() => {
    const divLeft = scrollableDivRefLeft.current;
    const divRight = scrollableDivRefRight.current;
    if (questionImageLoading || currentQuestion?.GetQuestion == undefined) {
      return;
    }

    if (divLeft) {
      //Left side has images to account for!
      if (
        setScrollChecks != undefined &&
        ScrollChecks[examinationState.currentQuestionIndex].left == false
      ) {
        setScrollChecks((prevState) => {
          const newState = [...prevState];
          newState[examinationState.currentQuestionIndex] = {
            ...newState[examinationState.currentQuestionIndex],
            left:
              divLeft.scrollHeight - divLeft.scrollTop === divLeft.clientHeight,
          };
          return newState;
        });
      }
    }
    if (divRight) {
      if (
        setScrollChecks != undefined &&
        ScrollChecks[examinationState.currentQuestionIndex].right == false
      ) {
        setScrollChecks((prevState) => {
          const newState = [...prevState];
          newState[examinationState.currentQuestionIndex] = {
            ...newState[examinationState.currentQuestionIndex],
            right:
              divRight.scrollHeight - divRight.scrollTop ===
              divRight.clientHeight,
          };
          return newState;
        });
      }
    }
  }, [
    currentQuestion?.GetQuestion,
    questionLoading,
    questionImageLoading,
    setScrollChecks,
  ]);

  return (
    <div
      className={`grid grid-cols-1 sm:grid-cols-2 bg-primaryBackground text-primaryText`}
      style={{ height: centerColumSize }}
    >
      {/* left column */}
      <div
        className={`p-4 overflow-y-auto`}
        ref={scrollableDivRefLeft}
        onScroll={handleScrollLeft}
      >
        <div className="p-4">
          {!questionLoading && (
            <>
              <>
                {DLOSCEMode == "DLOSCE" ? (
                  <CAPatientTable
                    currentQuestion={currentQuestion}
                    textSize={textSize}
                    getHighlightedText={getHighlightedText}
                    theme={userData.theme}
                  />
                ) : (
                  <USPatientTable
                    currentQuestion={currentQuestion}
                    textSize={textSize}
                    getHighlightedText={getHighlightedText}
                  />
                )}
              </>
              {/* peridontal */}
              {questionType != undefined && questionType == "Periodontal" && (
                <div className="flex flex-col mt-3 justify-start">
                  <PeridontalChart
                    extraData={
                      currentQuestion?.GetQuestion.extra_data.vestibular
                    }
                    textSize={textSize}
                    getHighlightedText={getHighlightedText}
                    altMode={false}
                  />
                  {
                    <PeridontalChart
                      extraData={
                        currentQuestion?.GetQuestion.extra_data.lingual
                      }
                      textSize={textSize}
                      getHighlightedText={getHighlightedText}
                      altMode={true}
                    />
                  }
                </div>
              )}
              {/* SNB */}
              {questionType != undefined && questionType == "SNB" && (
                <div className="flex flex-col mt-3 justify-start">
                  <Snbchart
                    extraData={currentQuestion?.GetQuestion.extra_data}
                    textSize={textSize}
                    getHighlightedText={getHighlightedText}
                  />
                </div>
              )}
              {/* Dental Exam*/}
              {questionType != undefined && questionType == "DentalExam" && (
                <div className="flex flex-col mt-3 justify-start">
                  <DentalExaminationChart
                    extraData={currentQuestion?.GetQuestion.extra_data.teeth}
                    textSize={textSize}
                    getHighlightedText={getHighlightedText}
                  />
                </div>
              )}
              {/* Moyor exam*/}
              {questionType != undefined && questionType == "Moyor" && (
                <div className="flex flex-col mt-3 justify-start space-y-5">
                  <MoyerPredictionChart
                    getHighlightedText={getHighlightedText}
                  />
                  <MoyerInputChart
                    getHighlightedText={getHighlightedText}
                    extraData={currentQuestion?.GetQuestion.extra_data}
                  />
                </div>
              )}
            </>
          )}
          {/* 3d image */}
          {
            <div className="flex mt-5 justify-center">
              <ErrorBoundary
                fallback={
                  <div className="flex space-x-3">
                    <AlertCircle className="w-20" />
                    <span>
                      The model displayer has failed to render, this might be
                      because your browser does not have WEBGL enabled. Please
                      enable WEBGL and restart this test to view the model.
                    </span>
                  </div>
                }
              >
                <ModelDisplayer
                  imagePath={questionType == "3D" ? imagePath : undefined}
                  questionLoading={questionLoading}
                  currentQuestionId={currentQuestionId}
                />
              </ErrorBoundary>
            </div>
          }
          {/* Currently loading question */}
          {questionLoading && (
            <>
              <span>Loading question...</span>
              {/*<ExamLoadingSpinner centerColumSize={centerColumSize} />*/}
            </>
          )}
          {/* exam image */}
          {currentQuestionImage && !questionImageLoading && (
            <div className="flex mt-5 justify-center">
              <img
                src={currentQuestionImage}
                alt="Question"
                onLoad={() => URL.revokeObjectURL(currentQuestionImage)}
              />
            </div>
          )}
          {/* image loading notif */}
          {questionImageLoading && (
            <div className="mt-5">
              <span>Loading image...</span>
            </div>
          )}
        </div>
      </div>
      {/* right column */}
      <div
        className={`p-4 flex flex-col overflow-y-auto ${textSize.right} scrollbar-left`}
        ref={scrollableDivRefRight}
        onScroll={handleScrollRight}
      >
        {/* Currently loading question */}
        {questionLoading && (
          <>
            <span>Loading question...</span>
            {/* <ExamLoadingSpinner centerColumSize={centerColumSize} />*/}
          </>
        )}
        {!questionLoading && (
          <>
            {questionType != "Prescription" && (
              <MultipleChoiceAnswerBlock
                processQuestionText={processQuestionText}
                GetShuffledAnswerArray={GetShuffledAnswerArray}
                currentQuestion={currentQuestion}
                completedAnswerArray={completedAnswerArray}
                examinationState={examinationState}
                createdExam={createdExam}
                handleAnswerChecked={handleAnswerChecked}
                processQuestionLabel={processQuestionLabel}
                getHighlightedText={getHighlightedText}
                showChecksAndXes={showChecksAndXes}
                showExplanations={showExplanations}
                textSize={textSize}
              />
            )}
            {questionType != undefined && questionType == "Prescription" && (
              <PerscriptionAnswerBlock
                examinationState={examinationState}
                setExaminationState={setExaminationState}
                textSize={textSize}
                currentQuestionId={currentQuestionId}
                ShowMyAnswerMode={ShowMyAnswerMode}
                reviewData={reviewData}
                reviewMode={reviewData != undefined}
                ShowAnswerMode={undefined}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TestCenterColumns;
