import { useDispatch, useSelector } from "react-redux";
import { getUserCategories, getUserData, setUserCategories } from "../store/login.slice";
import AccordionItem from "../components/accordion/accordion";
import ToolTip from "../components/tooltip/tooltip";
import ToggleButton from "../components/toggleButton/toggleButton";
import RadioButton from "../components/radioButton/radioButton";
import CheckBox from "../components/checkbox/checkbox";
import { useNavigate } from "react-router-dom";
import { setCreatedTest, setTestStart } from "../store/test.slice";
import { useEffect, useState } from "react";
import { useStartTestMutation } from "../api/testSlice.ts/testSlice";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";
import {
  useGrabCategoryCountsQuery,
  useUpdatetestmodeMutation,
} from "../api/accountSlice/accountSlice";
//Page specific component
function subjectSystemsComponent(index, item, count = 0, checked, setChecked) {
  return (
    <div key={`${index}-${item}`} className="flex space-x-3 items-start">
      <CheckBox checked={checked} text={undefined} setChecked={setChecked} />
      <span>{item}</span>
      <span className="ms-3 text-sm font-medium text-blue-600 dark:text-gray-300 border border-gray-400 rounded-full p-0.5 h-7">
        {count}
      </span>
    </div>
  );
}

function CreateTest() {
  //
  //Selectors
  //
  const categories = useSelector(getUserCategories);
  const userData = useSelector(getUserData);
  const dispatch = useDispatch()
  //
  //local state
  //
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  const [errorState, setErrorState] = useState(false);
  const [warningState, setWarningState] = useState("");
  const [testModeData, setTestModeData] = useState(userData.test_mode);
  const [questionModesData, setQuestionModesData] = useState({
    Unused: true,
    Correct: false,
    Incorrect: false,
    Marked: false,
    Omitted: false,
  });
  const defaultCategories = categories.map((element) => {
    return element.Category;
  });
  const [subjectsData, setSubjectsData] = useState(defaultCategories);
  const [numberOfQuestions, setNumberOfQuestions] = useState<
    number | string | undefined
  >(10);
  //
  //Queries
  //
  const [startTest] = useStartTestMutation();
  const [updatetestmode] = useUpdatetestmodeMutation();
  const { data } = useGrabCategoryCountsQuery({});
  useEffect(() => {
    if (data) {
      dispatch(setUserCategories({categories:[...data]}));
    }
  }, [data, dispatch]);
  //
  //Hooks
  //
  useTokenSpoilCheck(errorStatusCode);
  const navigate = useNavigate();

  useEffect(() => {
    updatetestmode({
      mode: testModeData,
    });
  }, [testModeData, updatetestmode]);

  //
  //Util funcs
  //
  function getQuestionCountByCategoryKey(key) {
    const Index = categories.findIndex((element) => {
      return element.Category == key;
    });

    let TotalCount = 0;

    questionModesData.Unused
      ? (TotalCount += categories[Index].TotalNumberUnusedInCategory)
      : TotalCount;
    questionModesData.Correct
      ? (TotalCount += categories[Index].TotalNumberCorrectInCategory)
      : TotalCount;
    questionModesData.Incorrect
      ? (TotalCount += categories[Index].TotalNumberInCorrectInCategory)
      : TotalCount;
    questionModesData.Marked
      ? (TotalCount += categories[Index].TotalNumberMarkedInCategory)
      : TotalCount;
    questionModesData.Omitted
      ? (TotalCount += categories[Index].TotalNumberOmittedInCategory)
      : TotalCount;

    return TotalCount;
  }

  return (
    <>
      <div>
        <div className="bg-primaryBackground text-secondaryText font-normal p-4 flex justify-between w-full">
          <span className="text-3xl">Create Test</span>
        </div>
        <div className="rounded-lg bg-primaryBackground mx-5 mt-1 mb-2">
          <AccordionItem
            header={
              <div className="flex items-center">
                <span className="mr-2">Test Mode</span>{" "}
                <ToolTip
                  tip={
                    "Test in timed or tutor mode. Tutor mode will grade each question immediately after you answer it."
                  }
                  width={"w-[300px]"}
                  position="top"
                />
              </div>
            }
            children={
              <div className="flex space-x-5">
                <ToggleButton
                  text={"Tutor"}
                  checked={testModeData == "Tutor"}
                  setChecked={() => {
                    setTestModeData(
                      testModeData == "Tutor" ? "Timed" : "Tutor"
                    );
                  }}
                />
                <ToggleButton
                  text={"Timed"}
                  checked={testModeData == "Timed"}
                  setChecked={() => {
                    setTestModeData(
                      testModeData == "Timed" ? "Tutor" : "Timed"
                    );
                  }}
                />
              </div>
            }
            initialActive={true}
            disableAccordion={true}
          />

          <AccordionItem
            header={
              <div className="flex items-center">
                <span className="mr-2">Question Mode</span>{" "}
                <ToolTip
                  tip={"Filter the questions you would like to study."}
                  width={undefined}
                />
              </div>
            }
            children={
              <div className="flex sm:space-x-5 space-y-2 sm:flex-row flex-col">
                <RadioButton
                  text={"Unused"}
                  count={userData.UnusedQuestions}
                  checked={questionModesData.Unused}
                  onChange={() => {
                    setQuestionModesData({
                      ...questionModesData,
                      Unused: !questionModesData.Unused,
                    });
                  }}
                />
                <RadioButton
                  text={"Correct"}
                  count={userData.CorrectQuestions}
                  checked={questionModesData.Correct}
                  onChange={() => {
                    setQuestionModesData({
                      ...questionModesData,
                      Correct: !questionModesData.Correct,
                    });
                  }}
                />
                <RadioButton
                  text={"Incorrect"}
                  count={userData.IncorrectQuestions}
                  checked={questionModesData.Incorrect}
                  onChange={() => {
                    setQuestionModesData({
                      ...questionModesData,
                      Incorrect: !questionModesData.Incorrect,
                    });
                  }}
                />
                <RadioButton
                  text={"Marked"}
                  count={userData.MarkedQuestions}
                  checked={questionModesData.Marked}
                  onChange={() => {
                    setQuestionModesData({
                      ...questionModesData,
                      Marked: !questionModesData.Marked,
                    });
                  }}
                />
                <RadioButton
                  text={"Omitted"}
                  count={userData.OmittedQuestions}
                  checked={questionModesData.Omitted}
                  onChange={() => {
                    setQuestionModesData({
                      ...questionModesData,
                      Omitted: !questionModesData.Omitted,
                    });
                  }}
                />
              </div>
            }
            initialActive={true}
            disableAccordion={true}
          />

          <AccordionItem
            header={
              <div className="flex space-x-3">
                <CheckBox
                  checked={subjectsData.length}
                  text={undefined}
                  setChecked={(checked) => {
                    checked
                      ? setSubjectsData(defaultCategories)
                      : setSubjectsData([]);
                  }}
                />
                <span className="mr-2">Subjects</span>
              </div>
            }
            children={
              <div className="grid sm:grid-cols-2 sm:gap-4">
                <div className="sm:p-4">
                  {categories.map((item, index) => {
                    // Check if the index is even (every second element)
                    if (index % 2 === 0) {
                      return subjectSystemsComponent(
                        index,
                        item.Category,
                        getQuestionCountByCategoryKey(item.Category),
                        subjectsData.includes(item.Category),
                        () => {
                          let tempArray = [...subjectsData];
                          if (tempArray.includes(item.Category)) {
                            setSubjectsData(
                              tempArray.filter(
                                (element) => element !== item.Category
                              )
                            );
                          } else {
                            setSubjectsData([...tempArray, item.Category]);
                          }
                        }
                      );
                    }
                    return null; // Return null for non-second elements (odd index)
                  })}
                </div>
                <div className="sm:p-4">
                  {categories.map((item, index) => {
                    // Check if the index is odd (every second element)
                    if (index % 2 === 1) {
                      return subjectSystemsComponent(
                        index,
                        item.Category,
                        getQuestionCountByCategoryKey(item.Category),
                        subjectsData.includes(item.Category),
                        () => {
                          let tempArray = [...subjectsData];
                          if (tempArray.includes(item.Category)) {
                            setSubjectsData(
                              tempArray.filter(
                                (element) => element !== item.Category
                              )
                            );
                          } else {
                            setSubjectsData([...tempArray, item.Category]);
                          }
                        }
                      );
                    }
                    return null; // Return null for non-second elements (odd index)
                  })}
                </div>
              </div>
            }
            initialActive={true}
            disableAccordion={true}
          />
          <AccordionItem
            header={
              <div className="flex items-center">
                <span className="mr-2">No. of Questions</span>
              </div>
            }
            children={
              <div className="flex items-center space-x-3">
                <input
                  type="number"
                  id="numberInput"
                  className="bg-secondaryBackground w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="0"
                  min={0}
                  max={50}
                  value={numberOfQuestions}
                  onChange={(e) => {
                    setNumberOfQuestions(e.target.value);
                  }}
                />
                <label
                  htmlFor="numberInput"
                  className="text-secondaryText mb-2"
                >
                  Max allowed per block
                </label>
                <span className="ms-3 text-sm font-medium text-blue-600 dark:text-gray-300 border border-gray-400 rounded-full p-0.5">
                  {50}
                </span>
              </div>
            }
            initialActive={true}
            disableAccordion={true}
          />
        </div>
        <div className="flex items-center space-x-3 mx-5">
          <button
            className="bg-primaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-secondaryDark w"
            onClick={async () => {
              setErrorState(false);
              if (subjectsData.length == 0) {
                setWarningState(
                  "You must select at least 1 subject before creating a new test."
                );
                return;
              } else {
                let subjectsFillable = true;
                let subjectsEnough = true;
                let subjectTotalCount = 0;
                subjectsData.forEach((element) => {
                  subjectTotalCount += getQuestionCountByCategoryKey(element);
                });
                if (subjectTotalCount == 0) {
                  subjectsFillable = false;
                }

                if (subjectTotalCount < Number(numberOfQuestions)) {
                  subjectsEnough = false;
                }

                if (!subjectsFillable) {
                  setWarningState(
                    "The selected subject(s) must have at least 1 question."
                  );
                  return;
                }

                if (!subjectsEnough) {
                  setWarningState(
                    "The selected subject(s) do not have enough questions!"
                  );
                  return;
                }
              }

              if (numberOfQuestions == 0) {
                setWarningState(
                  "Each test must have at least 1 question in the block."
                );
                return;
              }
              const testObject = {
                TutorMode: testModeData == "Tutor",
                Subjects: subjectsData,
                Unused: questionModesData.Unused,
                Correct: questionModesData.Correct,
                Incorrect: questionModesData.Incorrect,
                Marked: questionModesData.Marked,
                Omitted: questionModesData.Omitted,
                NumQuestions: Number(numberOfQuestions),
              };
              try {
                //Generate some sort of test
                const result = await startTest({
                  testStartData: testObject,
                }).unwrap();
                dispatch(
                  setCreatedTest({
                    createdTest: {
                      QuestionsForTest: result.QuestionsForTest,
                      FirstQuestion: result.FirstQuestion,
                      AnswerChoicesForTest: result?.AnswerChoicesForTest,
                      guid: result.guid,
                      tutorMode: testModeData == "Tutor",
                    },
                  })
                );
              } catch (error) {
                setErrorState(true);
                console.error("Error occurred during test creation:", error);
                setErrorStatusCode(error?.status);
                return;
              }

              //Set an end point for the test for now for now
              dispatch(
                setTestStart({
                  testStartDate: Date.now(),
                })
              );

              //Navigate to the test portion
              navigate("/test");
            }}
          >
            Generate Test
          </button>
          <ToolTip
            tip={"Click to generate a test"}
            position="top"
            width={undefined}
          />
          {errorState && (
            <span className="text-red-600 font-bold">
              Something went wrong, please try again
            </span>
          )}
          {warningState && (
            <span className="text-red-600 font-bold">{warningState}</span>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateTest;
