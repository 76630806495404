import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getTestExtraSeconds,
  getTestStartSecondsSelector,
  getTestStartSelector,
} from "../../store/test.slice";
import { useUpdateProgressMutation } from "../../api/testSlice.ts/testSlice";
import Modal from "../modal/Modal";
import { useNavigate } from "react-router-dom";
import { TestSource } from "../../constants/test";

function ExamSaver({
  examinationState,
  createdExam,
  numberOfQuestions,
  navigatorOpen,
  setErrorStatusCode,
  exitExamOpen,
  setExitExamOpen,
  aboutToEndExam,
  outOfTimeOpen,
  questionType,
}) {
  const navigate = useNavigate();
  const testStartDate = useSelector(getTestStartSelector);
  const testStartSeconds = useSelector(getTestStartSecondsSelector);
  const testExtraSeconds = useSelector(getTestExtraSeconds);
  let testSource = TestSource.Fresh;
  let targetTime;
  if (testStartSeconds == undefined) {
    targetTime =
      testStartDate +
      numberOfQuestions * 2 * 60 * 1000 +
      testExtraSeconds * 1000;
    testSource = TestSource.Fresh;
  } else {
    const initialTimestamp = new Date(testStartDate);
    targetTime = new Date(
      initialTimestamp.getTime() +
        testStartSeconds * 1000 +
        testExtraSeconds * 1000
    );
    testSource = TestSource.Incomplete;
  }
  const [updateProgress, {}] = useUpdateProgressMutation();
  const DEBOUNCE_DELAY = 100;

  useEffect(() => {
    // Set up the debounce
    const handler = setTimeout(async () => {
      TryToSaveExamProgress();
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
    };
  }, [
    examinationState.currentQuestionIndex,
    navigatorOpen,
    aboutToEndExam,
    outOfTimeOpen,
  ]);

  useEffect(() => {
    const handleBackspace = (event) => {
      if (
        event.key === "Backspace" &&
        (questionType == undefined || questionType != "Prescription")
      ) {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleBackspace);

    return () => {
      window.removeEventListener("keydown", handleBackspace);
    };
  }, [navigate]);

  async function TryToSaveExamProgress() {
    const cleanedNotes = Object.fromEntries(
      Object.entries(examinationState.notesById).filter(
        ([, value]) => value !== ""
      )
    );

    const cleanedReviews = Object.fromEntries(
      Object.entries(examinationState.reviewById).filter(
        ([, value]) => value !== false
      )
    );

    const currentTime = new Date().getTime();
    const secondsLeft = Math.max(
      0,
      Math.floor((targetTime - currentTime) / 1000)
    );
    try {
      await updateProgress({
        testData: {
          examGuid: createdExam.guid,
          answersById: { ...examinationState.answersById },
          notesById: { ...cleanedNotes },
          textModificationsById: { ...examinationState.textModificationsById },
          reviewById: { ...cleanedReviews },
          answerOrdersById: { ...examinationState.answerOrdersById },
          answerChangesById: { ...examinationState.answerChangesById },
          answerChoices: { ...examinationState.answerChoices },
          timeleft: secondsLeft,
          tutorialMode: [...examinationState.tutorialMode],
          prescriptions: { ...examinationState.prescriptions },
        },
      }).unwrap();
    } catch (error) {
      console.log(error);
      setErrorStatusCode(error?.status);
      return;
    }
  }
  return (
    <>
      {" "}
      <Modal
        isOpen={exitExamOpen}
        onClose={() => {
          setExitExamOpen(false);
        }}
        title={"Confirm exit"}
        submit={true}
        onSubmit={async () => {
          await TryToSaveExamProgress();
          testSource == TestSource.Fresh
            ? navigate("/CreateTest")
            : navigate("/incompletetests");
        }}
        fullWidth={false}
        submitText={"Yes"}
        cancelText={"No"}
        closeHidden={undefined}
        pixelWidth={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <div className="bg-primaryBackground text-primaryText p-4 m-3">
          Are you sure you would like to exit this test? This will not grade any
          questions that have been answered. Your progress will be saved and
          will be continued under the Incomplete Tests section.
        </div>
      </Modal>
    </>
  );
}

export default ExamSaver;
