import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../components/toggleButton/toggleButton";
import {
  getUserData,
  getUserDataTimeZone,
  setLogout,
  setUserDataTheme,
  setUserDataTimeZone,
  setUserDataWarnings,
} from "../store/login.slice";
import {
  useSetPasswordMutation,
  useSetThemeMutation,
  useSetTimeZoneMutation,
  useUpdateWarningsMutation,
} from "../api/accountSlice/accountSlice";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
import { useState } from "react";
import Modal from "../components/modal/Modal";
import { toast } from "react-toastify";
import { Eye } from "lucide-react";
import useTokenSpoilCheck from "../hooks/tokenspoilcheck";
import { useSubmitFeedbackByQuestionIdMutation } from "../api/feedbackSlice/feedbackSlice";
import { daysUntil, formatDateTime } from "../helpers/formatters";
import { getAuthData, invalidateToken } from "../helpers/auth";

function AccountSettings() {
  //
  //Selectors
  //
  const userData = useSelector(getUserData);
  const timeZone = useSelector(getUserDataTimeZone);

  //
  //local state
  //
  const [feedback, setFeedback] = useState({
    open: false,
    feedback: "",
    result: "",
  });
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [last4Dgitis, setLast4Digits] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [passwordError, setPasswordError] = useState<undefined | string>();
  const [difficultyLevel, setDifficultyLevel] = useState(userData.difficulty);
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    userData.time_zone
      ? userData.time_zone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  //
  //Hooks
  //
  const dispatch = useDispatch();

  const [
    submitFeedback,
    {
      isLoading: feedbackIsLoading,
      isSuccess: feedbackIsSuccess,
      reset: feedbackReset,
    },
  ] = useSubmitFeedbackByQuestionIdMutation();

  useTokenSpoilCheck(errorStatusCode);

  //
  //Queries
  const [setTheme] = useSetThemeMutation();
  //

  const [setTimeZone] = useSetTimeZoneMutation();

  const [setPassword] = useSetPasswordMutation();

  const [updateWarnings] = useUpdateWarningsMutation();

  //
  //Util funcs
  //
  async function setUserTheme(theme) {
    let result = undefined;
    try {
      result = await setTheme({
        theme: theme,
      }).unwrap();
      dispatch(setUserDataTheme({ theme: theme }));
      toast("Theme set");
    } catch (error) {
      console.log(error);
      setErrorStatusCode(error?.status);
      return;
    }
    toast(
      result?.error?.data?.message
        ? result?.error?.data?.message
        : result?.data?.message
    );
  }
  async function setUserTimeZone(timeZone) {
    try {
      await setTimeZone({
        time_zone: JSON.stringify(timeZone),
      }).unwrap();
    } catch (error) {
      setErrorStatusCode(error?.status);
      console.log(error);
      return;
    }
    toast("Timezone updated!");
    setSelectedTimezone(timeZone);
    dispatch(setUserDataTimeZone({ time_zone: timeZone }));
  }
  async function setUserPassword() {
    if (passwords.new_password != passwords.confirm_password) {
      setPasswordError("New passwords do not match");
      return;
    }
    let result = undefined;
    try {
      result = await setPassword({
        password: {
          old_password: passwords.old_password,
          new_password: passwords.new_password,
        },
      }).unwrap();
      if (result?.error?.data?.message) {
        console.log("Failed to set password");
        setPasswordError(result?.error?.data?.message);
        setPasswords({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
        return;
      }
    } catch (error) {
      console.log("Failed to set password", error);
      setErrorStatusCode(error?.status);
      setPasswordError(error.data.message);
      setPasswords({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      return;
    }
    toast(result.message);
    setPasswordError(undefined);
    setChangePasswordOpen(false);
  }
  function logout() {
    invalidateToken();
    dispatch(setLogout());
  }
  async function fetchReceipt() {
    const loginData = JSON.parse(getAuthData());
    const tokenCookie = loginData?.token;
    fetch("https://app.oscedental.com/sendreceipt.webapi", {
      method: "POST",
      headers: {
        TOKEN: tokenCookie,
      },
      body: JSON.stringify({
        last4: Number(last4Dgitis),
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        html = html.replace(
          `<div style="text-align: center;">`,
          `<div style="text-align: center;display: flex;justify-content: center;">`
        );
        setHtmlContent(html);
      })
      .catch((error) => console.error("Error fetching HTML:", error));
  }
  const handlePrint = () => {
    const windowPrint = window.open("", "_blank");
    windowPrint.document.write(`${htmlContent}`);
    windowPrint.document.close();
    windowPrint.focus();
    windowPrint.print();
    windowPrint.close();
  };

  return (
    <>
      <div className="bg-primaryBackground text-secondaryText font-normal p-4 flex justify-between w-full">
        <div className="flex flex-row justify-between w-full">
          <span className="text-3xl">Account Settings</span>
          <button
            className="bg-secondaryDark text-contrastText font-bold py-2 px-4 rounded hover:bg-primaryDark w"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="rounded-lg bg-primaryBackground m-5 text-primaryText p-5">
        {/*"Profile details"*/}
        <span className="font-bold">Profile Details</span>
        <div className="flex flex-col space-y-3">
          <div className="flex justify-between">
            <span className="">Name</span>
            <span className="">{userData.customer_email}</span>
          </div>
          <div className="flex justify-between">
            <span className="">Username</span>
            <span className="">
              {userData.customer_first_name} {userData.customer_last_name}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="">Account Creation Date</span>
            <span className="">
              {formatDateTime(
                timeZone.value,
                new Date(userData.date_cust_created)
              )}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="">Demo</span>
            <span className="">{userData.demo ? "Yes" : "No"}</span>
          </div>
          <div className="flex justify-between">
            <span className="">Subscription Expiration</span>
            <span className="">
              {formatDateTime(
                timeZone.value,
                new Date(userData.license_expiration)
              )}
            </span>
          </div>
        </div>
      </div>
      {/*"Theme"*/}
      <div className="rounded-lg bg-primaryBackground m-5 text-primaryText p-5">
        {/**/}
        <div className="flex flex-col mb-2">
          <span className="font-bold mb-2">Theme</span>
          <span className="mb-2">
            Personalize your studying by selecting a theme that feels more like
            you.
          </span>
          <hr />
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="p-1">
              <div className="flex flex-col">
                <span className="font-bold text-xl">Classic</span>
                <span className="text-sm">
                  Our personal favorite - A modest design with high contrast
                  accents
                </span>
                <div className="flex space-x-4 mb-2 mt-2 theme-default">
                  <div className="w-[50px] h-[50px] bg-primaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-primaryLight rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryLight rounded"></div>
                </div>
                <button
                  className="bg-primaryDark text-contrastText h-12"
                  onClick={() => {
                    setUserTheme("default");
                  }}
                >
                  Activate
                </button>
              </div>
            </div>
            <div className="p-1">
              <div className="flex flex-col">
                <span className="font-bold text-xl">Lavender Mist</span>
                <span className="text-sm">Classic theme</span>
                <div className="flex space-x-4 mb-2 mt-2 theme-ocean">
                  <div className="w-[50px] h-[50px] bg-primaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-primaryLight rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryLight rounded"></div>
                </div>
                <button
                  className="bg-primaryDark text-white h-12"
                  onClick={() => {
                    setUserTheme("ocean");
                  }}
                >
                  Activate
                </button>
              </div>
            </div>
            <div className="p-1">
              <div className="flex flex-col">
                <span className="font-bold text-xl">Dark</span>
                <span className="text-sm">Dark mode theme</span>
                <div className="flex space-x-4 mb-2 mt-2 theme-dark">
                  <div className="w-[50px] h-[50px] bg-primaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryDark rounded"></div>
                  <div className="w-[50px] h-[50px] bg-primaryLight rounded"></div>
                  <div className="w-[50px] h-[50px] bg-secondaryLight rounded"></div>
                </div>
                <button
                  className="bg-primaryDark text-white h-12"
                  onClick={() => {
                    setUserTheme("dark");
                  }}
                >
                  Activate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            {/*Col 1*/}
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Change Password</span>
                <span className="text-sm">
                  Change the password you use to login
                </span>
                <button
                  className="bg-primaryDark text-contrastText h-12"
                  onClick={() => {
                    setChangePasswordOpen(true);
                    setPasswords({
                      old_password: "",
                      new_password: "",
                      confirm_password: "",
                    });
                  }}
                >
                  Change password
                </button>
              </div>
            </div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Report Bug/Feedback</span>
                <span className="text-sm">
                  Noticed a bug or have something to share with us? Let us know
                  here.
                </span>
                <button
                  className="bg-primaryDark text-contrastText h-12"
                  onClick={() => {
                    setFeedback({ open: true, feedback: "", result: "" });
                  }}
                >
                  Report Bug/Feedback
                </button>
              </div>
            </div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Difficulty</span>
                <div className="flex space-x-3">
                  <span className="font-bold text-lg">Less Difficult</span>
                  <ToggleButton
                    text={undefined}
                    checked={difficultyLevel == "easy"}
                    setChecked={() => {
                      setDifficultyLevel("easy");
                    }}
                  />
                </div>
                <span className="text-sm">
                  This selection will show fewer answer options than typical on
                  the real test.
                </span>
                <div className="flex space-x-3 ">
                  <span className="font-bold text-lg">Difficult </span>
                  <ToggleButton
                    text={undefined}
                    checked={difficultyLevel == "medium"}
                    setChecked={() => {
                      setDifficultyLevel("medium");
                    }}
                  />
                </div>
                <span className="text-sm">
                  This selection will show roughly the same number of answer
                  options as on the real test.
                </span>
                <div className="flex space-x-3 ">
                  <span className="font-bold text-lg">Most Difficult</span>
                  <ToggleButton
                    text={undefined}
                    checked={difficultyLevel == "hard"}
                    setChecked={() => {
                      setDifficultyLevel("hard");
                    }}
                  />
                </div>
                <span className="text-sm">
                  This selection will show many more answer options than what's
                  typical on the real test.
                </span>
              </div>
            </div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Warnings</span>
                <div className="flex space-x-3">
                  <span className="font-bold text-lg">Enabled</span>
                  <ToggleButton
                    text={undefined}
                    checked={!userData.hide_warning}
                    setChecked={() => {
                      updateWarnings({ warnings: !userData.hide_warning });
                      dispatch(
                        setUserDataWarnings({
                          hide_warning: !userData.hide_warning,
                        })
                      );
                    }}
                  />
                </div>
                <span className="text-sm">
                  Warnings appear when you click on an action that requires a
                  confirmation
                </span>
              </div>
            </div>
          </div>
          {/*Col 2*/}
          <div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Resubscribe</span>
                <span className="text-sm">
                  You have{" "}
                  <span className="font-bold">
                    {daysUntil(new Date(userData.license_expiration))} days
                  </span>{" "}
                  remaining in your subscription ending on{" "}
                  <span className="font-bold">
                    {" "}
                    {formatDateTime(
                      timeZone.value,
                      new Date(userData.license_expiration)
                    )}{" "}
                  </span>
                  if you need more time to study you can resubsribe by following
                  these instructions
                </span>
                <div className="flex flex-col space-y-5">
                  <div className="flex space-x-3 mt-3 mb-3">
                    <span className="text-3xl">1</span>
                    <span>
                      Click "Resubscribe" or navigate to "OsceDental Purchase
                      Link"
                    </span>
                  </div>
                  <div className="flex space-x-3">
                    <span className="text-3xl">2</span>
                    <span>
                      Select the amount of time that you would like to extend
                      your subscription
                    </span>
                  </div>
                  <div className="flex space-x-3">
                    <span className="text-3xl">3</span>
                    <span>
                      At checkout, use the same e-mail address that you used to
                      subscribe originally. The system will automatically locate
                      your account and extend your subscription.
                    </span>
                  </div>
                </div>
                <button
                  className="bg-primaryDark text-white h-12 "
                  onClick={() => {
                    window.location.href = "https://oscedental.com/purchase";
                  }}
                >
                  Resubscribe
                </button>
              </div>
            </div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Time Zone</span>
                <span className="text-sm">
                  Setting your time zone will make device times like case
                  completion dates display in your local time instead of UTC
                </span>
                <div className="select-wrapper text-contrastText">
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setUserTimeZone}
                    displayValue="UTC"
                    styles={{
                      control: (base) => ({
                        ...base,
                        color: "black",
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color: "black",
                      }),
                      option: (base, { isFocused }) => ({
                        ...base,
                        color: isFocused ? "black" : "black",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-primaryBackground m-5 text-secondaryText p-5">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xl">Print Receipt</span>
                <span className="text-sm">
                  Need a copy of your account receipt? You can download a copy
                  or print it here
                </span>
                <div className="select-wrapper text-contrastText">
                  <button
                    className="bg-primaryDark text-white h-12 w-full"
                    onClick={() => {
                      setReceiptOpen(true);
                    }}
                  >
                    Print Receipt
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changePasswordOpen}
        onClose={() => {
          setPasswordError("");
          setChangePasswordOpen(false);
        }}
        title={"Change password"}
        submit={true}
        onSubmit={() => {
          console.log("Submitting");
          setUserPassword();
        }}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <div className="flex flex-col mb-10">
          <form action="#">
            <div>
              <label
                className="block text-sm font-medium text-secondaryText"
                htmlFor="oldpassword"
              >
                Old Password
              </label>
              <div className="mt-1 flex">
                <input
                  id="oldpassword"
                  name="oldpassword"
                  autoComplete="on"
                  type={showPassword ? "text" : "password"}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={passwords.old_password}
                  onChange={(e) => {
                    setPasswords((prevState) => ({
                      ...prevState,
                      old_password: e.target.value,
                    }));
                  }}
                />
                <div
                  className="flex mx-2 my-auto"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Eye />
                </div>
              </div>
            </div>
            <div>
              <label
                className="block text-sm font-medium text-secondaryText"
                htmlFor="password"
              >
                New Password
              </label>
              <div className="mt-1 flex">
                <input
                  id="password"
                  name="password"
                  autoComplete="on"
                  type={showPassword ? "text" : "password"}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={passwords.new_password}
                  onChange={(e) => {
                    setPasswords((prevState) => ({
                      ...prevState,
                      new_password: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className="block text-sm font-medium text-secondaryText"
                htmlFor="newpassword"
              >
                Confirm New Password
              </label>
              <div className="mt-1 flex">
                <input
                  id="newpassword"
                  name="newpassword"
                  autoComplete="on"
                  type={showPassword ? "text" : "password"}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={passwords.confirm_password}
                  onChange={(e) => {
                    setPasswords((prevState) => ({
                      ...prevState,
                      confirm_password: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            {passwordError && (
              <span className="text-red-500">{passwordError}</span>
            )}
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={feedback.open}
        onClose={() => {
          setFeedback({ open: false, feedback: "", result: "" });
          feedbackReset();
        }}
        title={`Feedback`}
        submit={!feedback.result}
        onSubmit={async () => {
          const result = await submitFeedback({
            feedback: feedback.feedback,
          }).unwrap();
          setFeedback({
            open: feedback.open,
            feedback: "",
            result: result.message,
          });
        }}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        {feedback.result || feedbackIsSuccess ? (
          <div className="mb-2">
            <span>{feedback.result}</span>
          </div>
        ) : feedbackIsLoading ? (
          <div>
            <span>Sending feedback...</span>
          </div>
        ) : (
          <textarea
            className="border border-gray-300 p-2 w-full mb-4 h-60 resize-none"
            placeholder="Tell us if you have any thoughts about the app..."
            onChange={(event) => {
              setFeedback({
                open: true,
                feedback: event.target.value,
                result: "",
              });
            }}
            value={feedback.feedback}
            autoComplete="off"
          />
        )}
      </Modal>
      <Modal
        isOpen={receiptOpen}
        onClose={() => {
          setReceiptOpen(false);
        }}
        title={`Print Your Receipt`}
        submit={true}
        onSubmit={async () => {
          fetchReceipt();
        }}
        closeHidden={undefined}
        fullWidth={undefined}
        pixelWidth={undefined}
        submitText={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
      >
        <div className="space-y-5 mb-3">
          <span>
            If you need the last four digits of your card added to your receipt,
            please enter them here.
          </span>
          <div className="space-y-3">
            <span>Last Four Digits of Card</span>
            <input
              id="card"
              name="card"
              type={"text"}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Last 4"
              value={last4Dgitis}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 4) {
                  setLast4Digits(value);
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={htmlContent != ""}
        onClose={() => {
          setHtmlContent("");
          setReceiptOpen(false);
        }}
        title={"Receipt"}
        submit={true}
        onSubmit={() => {
          handlePrint();
        }}
        fullWidth={true}
        closeHidden={undefined}
        pixelWidth={undefined}
        cancelText={undefined}
        xClose={undefined}
        submitDark={undefined}
        closeDark={undefined}
        buttonsSpaced={undefined}
        submitText={"Print"}
      >
        <div className="overflow-y-auto mb-5">
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className="max-h-[80vh]"
          />
        </div>
      </Modal>
    </>
  );
}

export default AccountSettings;
