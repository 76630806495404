export enum TestSource {
  Fresh = "Fresh",
  Incomplete = "Incomplete",
}

export enum Warnings {
  Incomplete = "This item is incomplete. Do you wish to continue?",
  View = "You must view the entire item before proceeding. Please scroll to the bottom of the page.",
  End = "You have chosen to end the review. Ending the review will not allow you to access these questions any further. Do you wish to end the review and grade your responses?",
}

export enum AnsweringResult {
  Correct = "Correct answer selected",
  Incorrect = "Incorrect answer selected",
  Unselected = "Correct answer not selected",
}

export const peridontalTempData = {
  "3.1": {
    bop: ["", "", ""],
    cal: ["3", "2", "3"],
    probing: ["3", "1", "3"],
    furcation: ["", "", ""],
    recession: ["", "1", ""],
  },
  "3.2": {
    bop: ["", "", ""],
    cal: ["3", "3", "3"],
    probing: ["3", "3", "3"],
    furcation: ["", "", ""],
    recession: ["", "", ""],
  },
  "4.1": {
    bop: ["", "", ""],
    cal: ["3", "3", "3"],
    probing: ["3", "3", "3"],
    furcation: ["", "", ""],
    recession: ["", "", ""],
  },
  "4.2": {
    bop: ["", "", ""],
    cal: ["3", "3", "3"],
    probing: ["3", "3", "3"],
    furcation: ["", "", ""],
    recession: ["", "", ""],
  },
};

export const snbChartTemp = {
  "Patient Values": {
    FMA: "TEST",
    SNA: "TEST",
    SNB: "TEST",
    "SN-MP": "TEST",
  },
};

export const dentalExamTemp = {
  "1.5": {
    probing: {
      lingual: ["", "", ""],
      vestibular: ["4", "5", "6"],
    },
    mobility: "",
    bite_test: "",
    cold_test: "+",
    heat_test: "",
    palpation: "",
    percussion: "",
    discoloration: "No",
    electric_pulp_test: "43/80",
  },
  "1.6": {
    probing: {
      lingual: ["", "", ""],
      vestibular: ["", "", ""],
    },
    mobility: "",
    bite_test: "",
    cold_test: "",
    heat_test: "",
    palpation: "",
    percussion: "",
    discoloration: "",
    electric_pulp_test: "",
  },
  "1.7": {
    probing: {
      lingual: ["", "", ""],
      vestibular: ["6", "7", "9"],
    },
    mobility: "1",
    bite_test: "",
    cold_test: "",
    heat_test: "",
    palpation: "",
    percussion: "",
    discoloration: "No",
    electric_pulp_test: "80/80",
  },
};

export const AnswerTempData = [
  {
    Answer: "Test answer",
    AnswerClause: "Correct answer selected",
    Reason: "test reason",
    Color: "red",
  },
  {
    Answer: "Test answer",
    AnswerClause: "Incorrect answer selected",
    Reason: "test reason",
    Color: "gray",
  },
  {
    Answer: "Test answer",
    AnswerClause: "Correct answer not selected",
    Reason: "test reason",
    Color: "green",
  },
];

export const YourAnswersTempData = {
  Points: 0,
  YourAnswers: [
    "TESTTESTTESTTESTTESTTESTTESTTEST",
    "TESTTESTTESTTESTTESTTESTTESTTEST",
    "Test",
  ],
};

export const perscriptionReview = {
  PrescriptionGrades: [
    {
      question_id: 10002,
      TotalPoints: 1,
      Explanation:
        "Amoxicillin 200mg for 2 weeks daily is the best treatment for this condition",
      Drug: {
        Points: 0,
        YourAnswer: "Daptomycin",
        CorrectAnswer: "Amoxicillin or Ampicillin or Penicillin",
        Color: "red",
      },
      Strength: {
        Points: 1,
        YourStrength: "200",
        YourStrengthUnits: "mg",
        CorrectStrength: "200",
        StrengthUnits: "mg",
        Color: "green",
      },
      Disp: {
        Points: 0,
        YourAnswer: "30",
        CorrectAnswer: "Invalid range in database",
        Color: "red",
      },
      Repeat: {
        Points: 0,
        YourAnswer: "4",
        CorrectAnswer: "Invalid range in database",
        Color: "red",
      },
      Sig: {
        Points: 0.25,
        IsCorrect: false,
        ComponentResults: {
          quantity: false,
          form: true,
          route: false,
          frequency: false,
        },
        Feedback: [
          "Incorrect quantity. You wrote: two, Expected: one",
          "Incorrect route. You wrote: , Expected: oral",
          "Incorrect frequency. You wrote: twice_daily, Expected: once_daily",
        ],
      },
    },
    {
      question_id: 10003,
      TotalPoints: 4,
      Explanation: "Lisinopril is the best",
      Drug: {
        Points: 1,
        YourAnswer: "Lisinocril (Note: correct spelling is 'Lisinopril')",
        CorrectAnswer: "Lisinopril or quetiapine",
        Color: "orange",
      },
      Strength: {
        Points: 1,
        YourStrength: "10",
        YourStrengthUnits: "mg",
        CorrectStrength: "10",
        StrengthUnits: "mg",
        Color: "green",
      },
      Disp: {
        Points: 1,
        YourAnswer: "90",
        CorrectAnswer: "60-90",
        Color: "green",
      },
      Repeat: {
        Points: 1,
        YourAnswer: "2",
        CorrectAnswer: "2-3",
        Color: "green",
      },
      Sig: {
        Points: 1,
        IsCorrect: true,
        ComponentResults: {
          quantity: true,
          form: true,
          route: true,
          frequency: true,
        },
        Feedback: [],
      },
    },
  ],
};

export const testRedBackground = "bg-[#ffdddd]";
export const testRedText = "text-[#411414]";
export const testgrayBackground = "bg-[#f2f2f2]";
export const testgrayText = "text-[#242424]";
export const testgreenBackground = "bg-[#E2FFDD]";
export const testgreenText = "text-[#175317]";
export const testorangeBackground = "bg-[#FFEFD5]";
export const testorangeText = "text-[#CC5500]";

export const defaultTimeZone = {
  value: "America/Boise",
  label: "(UTC-7:00) Mountain Time",
  offset: -7,
  abbrev: "MST",
  altName: "Mountain Standard Time",
};
