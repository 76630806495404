import { apiSlice } from "../apiSlice";

const secondaryUrl = "";
const testExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setTimeZone: builder.mutation({
      query: ({ time_zone }) => ({
        url: `${secondaryUrl}/settimezone.webapi`,
        method: "POST",
        body: { time_zone: time_zone },
      }),
    }),
    setTheme: builder.mutation({
      query: ({ theme }) => ({
        url: `${secondaryUrl}/updatetheme.webapi`,
        method: "POST",
        body: { theme },
      }),
    }),
    setPassword: builder.mutation({
      query: ({ password }) => ({
        url: `${secondaryUrl}/changepassword.webapi`,
        method: "POST",
        body: { ...password },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ email }) => ({
        url: `${secondaryUrl}/resetpassword.webapi`,
        method: "POST",
        body: { email: email },
      }),
    }),
    signUpToDemo: builder.mutation({
      query: ({ details }) => ({
        url: `${secondaryUrl}/demosignup.webapi`,
        method: "POST",
        body: { ...details },
      }),
    }),
    updateWarnings: builder.mutation({
      query: ({ warnings }) => ({
        url: `${secondaryUrl}/updatewarnings.webapi`,
        method: "POST",
        body: { warnings: warnings },
      }),
    }),
    getuserstats: builder.query({
      query: () => ({
        url: "getuserstats.webapi/",
      }),
    }),
    updatetestmode: builder.mutation({
      query: ({ mode }) => ({
        url: `${secondaryUrl}/updatetestmode.webapi`,
        method: "POST",
        body: { test_mode: mode },
      }),
    }),
    grabCategoryCounts: builder.query({
      query: () => ({
        url: `${secondaryUrl}/getcategories.webapi`,
      }),
    }),
  }),
});

export const {
  useSetTimeZoneMutation,
  useSetPasswordMutation,
  useSetThemeMutation,
  useResetPasswordMutation,
  useSignUpToDemoMutation,
  useUpdateWarningsMutation,
  useUpdatetestmodeMutation,
  useGetuserstatsQuery,
  useGrabCategoryCountsQuery
} = testExtendedSlice;
